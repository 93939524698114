import { Component, OnInit } from '@angular/core';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {Router} from '@angular/router';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';

@Component({
  selector: 'app-applications-list',
  templateUrl: './applications-list.component.html',
  styleUrls: ['./applications-list.component.scss']
})
export class ApplicationsListComponent implements OnInit {
  public applications: ApplicationModel[];
  public filterdApps: ApplicationModel[];
  public app: ApplicationModel;
  public showMessage: boolean;
  filterCriteria: FilterCriteria;
  config: any;
  term: any;
  removePagination = true;

  constructor(private router: Router,
              public applicationsService: ApplicationsService) { }

  ngOnInit(): void {

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys = [];
    /*this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa7');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa6');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa8');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa1');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa2');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa3');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa4');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa5');
    this.filterCriteria.ApplicationsKeys.push('3fa85f64-5717-4562-b3fc-2c963f66afa9');*/


    this.showMessage = false;

    this.applications = Array<ApplicationModel>();
    this.filterdApps =  Array<ApplicationModel>();

    this.fetchAll();

  }

  async fetchAllApplications(): Promise<void> {
    console.log(this.filterCriteria);

    const obs = this.applicationsService.GetByFilterCriteria(this.filterCriteria);
    this.applications = await obs.toPromise();


    //
    this.config = {
      itemsPerPage: 8,
      currentPage: 1,
      totalItems: this.applications.length
    };

    if (this.applications.length === 0) {
      this.showMessage = true;
    }

    if (this.applications.length >= 5) {
      this.removePagination = false;
    }


    console.log(this.applications);

  }

  fetchAll(): void {
    this.applicationsService.GetByFilterCriteria(this.filterCriteria).subscribe(applications => {
      this.applications = applications;

      const filteredApps = this.applications.filter( temp => temp.isDeleted !== true);
      this.filterdApps = filteredApps;

      this.config = {
        itemsPerPage: 8,
        currentPage: 1,
        totalItems: filteredApps.length
      };

      if (filteredApps.length === 0) {
        this.showMessage = true;
      }

      if (filteredApps.length >= 5) {
        this.removePagination = false;
      }

      console.log(this.applications);
    });
  }


  goToApplicationStages(application: ApplicationModel): void {
    this.router.navigate(['application-stages', application.id],
      {
        queryParams: {title: application.title}
      });
  }

  goToApplicationDetails(application: ApplicationModel): void {
    this.router.navigate(['view-application', application.id],
      {
      queryParams: {title: application.title}
      });
  }

  goToEditApplication(application: ApplicationModel): void {
    this.router.navigate(['edit-application', application.id]);
  }

  goToCandidatesList(application: ApplicationModel): void {
    this.router.navigate(['candidates-list', application.id],
        {
          queryParams: {title: application.title}
        });
  }

  pageChanged(event): void {
    this.config.currentPage = event;
  }
}
