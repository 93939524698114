export class FilterCriteria {
  JobKeys: string[];
  CandidateKeys: string[];
  QuestionKeys: string[];
  ProfileKeys: string[];
  StageKeys: string[];
  DocumentKeys: string[];
  ApplicationsKeys: string[];
  CompanyKeys: string[];
  AnswerKeys: string[];
  MailKeys: string[];
  ReferralKeys: string[];
  EmailKey: string;
}
