import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {ToasterNotificationService} from '../../../Services/ToasterNotificationService/ToasterNotification.Service';
import {LocationModel} from '../../../Core/Models/Location.Model';
import {DocumentUploadModel} from '../../../Core/Models/DocumentUpload.Model';
import {BannerImageModel} from '../../../Core/Models/BannerImage.Model';
import {environment} from '../../../../environments/environment';


@Component({
  selector: 'app-edit-application',
  templateUrl: './edit-application.component.html',
  styleUrls: ['./edit-application.component.scss']
})
export class EditApplicationComponent implements OnInit {

  application: ApplicationModel;
  url = '';
  filterCriteria: FilterCriteria;
  editApplicationForm: FormGroup;
  resultsUpload: DocumentUploadModel;
  shortDescriptionLength = 450;
  banner: File;
  bannerUrl: string;
  bucketName = environment.bucketName;
  bannerImageModel: BannerImageModel;

  constructor(private applicationsService: ApplicationsService,
              private documentsService: DocumentsService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private notification: ToasterNotificationService,
              private router: Router) {}

  ngOnInit(): void {
    this.resultsUpload = new DocumentUploadModel();

    this.initiateForm();
    this.fetchApplication();
  }

  initiateForm(): void {
    this.editApplicationForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3)]],
      addressLineOne: ['', [Validators.required, Validators.minLength(3)]],
      addressLineTwo: ['', [Validators.required, Validators.minLength(3)]],
      addressLineThree: ['', [Validators.required, Validators.minLength(3)]],
      zipCode: ['', [Validators.required, Validators.minLength(3)]],
      salary: '',
      availableDate: ['', [Validators.required]],
      expiryDate: ['', [Validators.required]],
      longDescription: ['', [Validators.required, Validators.minLength(3)]],
      shortDescription: ['',
        [Validators.required, Validators.minLength(3), Validators.maxLength(450)]],
      bannerImage: '',
      industry: ['', [Validators.required]],
      category: ['', [Validators.required]]
    });
  }

  async fetchApplication(): Promise<void> {

    const applicationId = this.route.snapshot.paramMap.get('id');

    this.applicationsService.GetById(applicationId).subscribe( response => {
      this.application = new ApplicationModel();
      this.application.location = new LocationModel();
      this.application = response;
      this.fetchApplicationBanner();

      if (this.application.bannerImage) {
        this.url = this.application.bannerImage;
      } else {
        this.url = 'assets/images/hazie-banner.jpg';
      }
    });
  }

  fetchApplicationBanner(): void {
    this.documentsService.GetApplicationBannerInfo(this.application.id).subscribe( bannerImageModel => {
      this.bannerImageModel = bannerImageModel;
      console.log(bannerImageModel);
    });
  }

  deleteBannerFileOnServer(): void {
    if (this.bannerImageModel && this.bannerImageModel.fileName) {
      this.documentsService.DeleteFile(this.bannerImageModel, this.bucketName).subscribe( response => {
        console.log(response);
      });
    } else {
      this.bannerImageModel = new BannerImageModel();
    }
  }

  validateSalary(salary: any): number {
    if (!salary) {
      return 0;
    } else {
      return salary;
    }
  }

  submit(): void {
    this.markFormFieldsAsTouched();
    if (this.editApplicationForm.valid) {

      if (this.banner) {
        const formData = new FormData();
        formData.append('formFiles', this.editApplicationForm.get('bannerImage').value, this.banner.name);
        const file = formData.get('formFiles');

        if (file) {
          this.deleteBannerFileOnServer();
          this.documentsService.uploadFile(this.bucketName, formData).subscribe(urlResponse => {

            this.bannerImageModel.fileName = this.banner.name;
            this.bannerImageModel.fileType = this.banner.type;
            this.bannerImageModel.fileSize = this.banner.size.toString();
            this.bannerImageModel.url = urlResponse.toString();
            this.bannerUrl = urlResponse.toString();

            this.documentsService.UpsertBanner(this.bannerImageModel).subscribe(response => {
              console.log('Banner Persisted to the DB');
            });
          });
        }
      }

      this.submitApplication();
    }
  }

  submitApplication(): void {
    const company = 'Hazie';
    this.application.companyId = '1f5f7eb5-734f-b345-ca11-5f75b9286328';
    this.application.salary = this.validateSalary(this.application.salary);
    this.application.industry = +this.application.industry;
    this.application.isActive = true;
    this.application.category = +this.application.category;
    if (this.bannerUrl) {
      this.application.bannerImage = this.bannerUrl;
    }
    this.application.shortDescription = this.application.shortDescription;
    this.application.longDescription = this.application.longDescription;
    this.application.isDeleted = false;
    this.application.dateCreated = new Date();
    this.application.deletedDate = this.application.expiryDate;
    this.application.orderBy = 0;
    this.application.location.applicationId = this.application.id;
    this.application.location.code = 0;

    this.applicationsService.Upsert(this.application).subscribe(response => {
      this.notification.Success('Application Updated');
    }, error => {
      this.notification.Failure('Application Update Failed');
    });
  }

  onChange(event): void {
    if (event.target.files.length > 0) {
      this.banner = event.target.files[0];
      this.editApplicationForm.get('bannerImage').setValue(this.banner);

      const reader = new FileReader();
      reader.onload = () => {
        /* file data to display */
        this.url = reader.result as string;
      };
      reader.readAsDataURL(this.banner);
    }
  }

  /* -Mark form as touched- */
  markFormFieldsAsTouched(): void {
    this.title.markAsTouched();
    this.addressLineOne.markAsTouched();
    this.addressLineTwo.markAsTouched();
    this.addressLineThree.markAsTouched();
    this.zipCode.markAsTouched();
    this.salary.markAsTouched();
    this.availableDate.markAsTouched();
    this.expiryDate.markAsTouched();
    this.longDescription.markAsTouched();
    this.shortDescription.markAsTouched();
    this.industry.markAsTouched();
    this.category.markAllAsTouched();
  }

  /* --EditApplicationForm Getters-- */

  get title(): AbstractControl {
    return this.editApplicationForm.get('title');
  }

  get addressLineOne(): AbstractControl {
    return this.editApplicationForm.get('addressLineOne');
  }

  get addressLineTwo(): AbstractControl {
    return this.editApplicationForm.get('addressLineTwo');
  }

  get addressLineThree(): AbstractControl {
    return this.editApplicationForm.get('addressLineThree');
  }

  get zipCode(): AbstractControl {
    return this.editApplicationForm.get('zipCode');
  }

  get salary(): AbstractControl {
    return this.editApplicationForm.get('salary');
  }

  get availableDate(): AbstractControl {
    return this.editApplicationForm.get('availableDate');
  }

  get expiryDate(): AbstractControl {
    return this.editApplicationForm.get('expiryDate');
  }

  get longDescription(): AbstractControl {
    return this.editApplicationForm.get('longDescription');
  }

  get shortDescription(): AbstractControl {
    return this.editApplicationForm.get('shortDescription');
  }

  get industry(): AbstractControl {
    return this.editApplicationForm.get('industry');
  }

  get category(): AbstractControl {
    return this.editApplicationForm.get('category');
  }

  goToApplicationsList(): void {
    this.router.navigate(['applications']);
  }
}
