<app-navbar></app-navbar>

<div class="main-container">
  <div class="activity-indicators">
    <div class="card">
      <div class="headers">
        Manage Users <br>
        <h2>Users</h2>
      </div>

      <div class="table">
        <div class="table-head">
          <div class="table-cell">Email</div>
          <div class="table-cell">Roles</div>
          <div class="table-cell">Actions</div>
        </div>
        <div class="table-body">
          <div class="message" *ngIf="showMessage">No Users</div>
          <div *ngIf="!showMessage">
            <div class="table-row" *ngFor="let user of users">
              <div class="table-cell">{{user.email}}</div>
              <div class="table-cell"><span *ngFor="let role of user.roleNames">{{role}} </span></div>
              <div class="table-cell button-row">
                <button class="button" type="submit" (click)="editUser(user.id)"><i class="fa fa-trash fa-2x" aria-hidden="true"></i></button>
                <button class="button" type="submit" (click)="deleteUser()"><i class="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
