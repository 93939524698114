<app-navbar></app-navbar>

<div class="main-container">
  <div class="title-section">
    CREATE STAGES FOR: {{applicationTitle}}
  </div>
  <p>Stages:</p>
  <div class="tabs">
    <div *ngIf="showMessage" class="message">
      {{applicationTitle}} has no stages
    </div>
    <div *ngIf="!showMessage"
         cdkDropList
         #list="cdkDropList"
         [cdkDropListData]="stageObjects"
         (cdkDropListDropped)="onDrop($event)">
      <div class="tab" *ngFor="let stage of stageObjects; let i = index" cdkDrag>
        <input type="checkbox" id="chck{{i}}">
        <label class="tab-label" for="chck{{i}}">{{i+1}}. {{stage.name}}</label>
        <div class="tab-content" id="stage{{i}}">
          <span class="stage-details">{{stage.stageType == "0"? "Basic" : stage.stageType == "1"? "Interview": stage.stageType == "3"?"Odyssey" :"Manual" }} Stage | Scoring: {{stage.isScoring ? "Yes" : "No"}} | Skippable: {{stage.skippable ? "Yes" : "No"}}</span>
          <app-load-stage-questions
            [stageModel]="stage"
            [stageIndex]="i"
            [applicationTitle]="applicationTitle"
            (returnDeleteStageIndex)="softDeleteStage($event)"
            (returnEditStageIndex)="openUpdateStageForm($event)">
          </app-load-stage-questions>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="curved-button button" (click)="openCreateStageForm()">
    CREATE STAGE
  </button>

  <!-- Create Stage Modal -->
  <jw-modal id="modal-1">
    <div class="header-row">
      <div class="header" *ngIf="isNewApplication">Create Stage</div>
      <div class="header" *ngIf="!isNewApplication">Edit Stage</div>
      <button class="close" (click)="modalService.close('modal-1')">
        <img src="../../../../assets/icons/close.svg" alt="">
      </button>
    </div>

    <div class="modal-body">
      <form [formGroup]="stageForm" >
        <div class="form-group">
          <label for="fname">Stage Name</label>
          <input type="text" id="fname" name="fname" formControlName="stageName">
          <div class="error-messages" *ngIf="stageName.touched">
            <div *ngIf="stageName.invalid && stageName.dirty">* Stage Name needs to be at least 3 characters </div>
            <div *ngIf="!stageName.value">* Stage Name can't be blank</div>
          </div>
        </div>
        <div class="form-group">
          <label for="stageType">Stage Type</label>
          <select id="stageType" formControlName="stageType">
            <option>-Select-Option-</option>
            <option value="0">Basic</option>
            <option value="1">Interview</option>
            <option value="2">Manual</option>
            <option value="3">Odyssey</option>
          </select>
          <div class="error-messages" *ngIf="stageType.touched">
            <div *ngIf="stageType.dirty && stageType.value === '-Select-Option-'">* Please choose correct stage type</div>
            <div *ngIf="stageType.invalid && !stageType.value">* Stage Type can't be blank</div>
          </div>
        </div>
        <div class="form-group" *ngIf="stageType.value !== '3' && stageType.value !== 3">
          <label for="scoring">Scoring</label>
          <select name="scoring" id="scoring" formControlName="scoring">
            <option>-Select-Option-</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <div class="error-messages" *ngIf="scoring.touched">
            <div *ngIf="scoring.dirty && scoring.value === '-Select-Option-'">* Please choose correct scoring option</div>
            <div *ngIf="scoring.invalid && !scoring.value">* Scoring can't be blank</div>
          </div>
        </div>
        <div class="form-group" *ngIf="(stageType.value !== '3' && stageType.value !== 3) &&
         (scoring.value !== 'true' && scoring.value !== true)">
          <label for="skippable">Skippable</label>
          <select id="skippable" formControlName="skippable">
            <option>-Select-Option-</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
          <div class="error-messages" *ngIf="skippable.touched">
            <div *ngIf="skippable.dirty && skippable.value === '-Select-Option-'">* Please choose correct skippable option</div>
            <div *ngIf="skippable.invalid && !skippable.value">* Skippable can't be blank</div>
          </div>
        </div>
        <button type="submit" class="button curved-button" (click)="createStage()" *ngIf="isNewApplication">
          CREATE
        </button>
        <button type="submit" class="button curved-button" (click)="updateStage()" *ngIf="!isNewApplication">
          UPDATE
        </button>
      </form>
      <br>
    </div>
  </jw-modal>

</div>
