
<div class="table">
  <div class="table-head" *ngIf="candidateAnswers.length > 0">
    <div class="table-cell">Question</div>
    <div class="table-cell">Answer</div>
  </div>

  <div class="table-head" *ngIf="candidateAnswers.length == 0 && stageModel.stageType != 1 && stageModel.stageType != 3">
    <div class="table-cell">{{stageModel.name}} has not yet been attempted</div>
    <div class="table-cell"></div>
  </div>

  <div class="table-body">
<!--    <div class="table-row" *ngFor="let answer of candidateAnswers">-->
<!--      <div class="table-cell">{{answer.question}} {{question.questionType}}</div>-->
<!--      <div *ngIf="question.questionType !== 1"  class="table-cell table-cell-border">{{answer.answer}}</div>-->
<!--      <div *ngIf="question.questionType === 1 && answer.answer" class="table-cell table-cell-border"><a href="{{answer.answer}}">View Document</a></div>-->
<!--      <div *ngIf="question.questionType === 1 && !answer.answer" class="table-cell table-cell-border">No document available</div>-->
<!--    </div>-->
    <div class="table-row" *ngFor="let answer of questionModelArray">
      <div class="table-cell">{{answer.question}}</div>
      <div *ngIf="answer.questionType !== 1 "  class="table-cell table-cell-border">{{answer.candidateAnswer}}</div>
      <div *ngIf="answer.questionType == 1 && answer.candidateAnswer" class="table-cell table-cell-border"><a target="_blank" href="{{answer.candidateAnswer}}">View Document</a></div>
      <div *ngIf="answer.questionType == 1 && !answer.candidateAnswer" class="table-cell table-cell-border">Document Not Available</div>
    </div>

    <div class="interview" *ngIf="stageModel.stageType == 1">
      <div class="calender">
        <app-fullcalender
          [candidateName]="candidateName"
          [candidateSurname]="candidateSurname"
          [interviewModel]="interviewModel"
          (returnInterviewModel)="fetchInterviewModelWithDate($event)"
        ></app-fullcalender>
      </div>

      <!--      edit only-->
      <div class="interview-form" *ngIf="!isScheduled">
        <form [formGroup]="interviewForm">
          <div class="row">
            <div class="form-group">
              <label for="startTime">Start Time:</label>
              <input type="time" id="startTime" value="00:00" formControlName="starttime" [(ngModel)]="interviewModel.startTime">
              <div class="error-messages" *ngIf="startTime.touched">
                <div *ngIf="!startTime.value">Start Time can't be blank</div>
              </div>
            </div>
            <div class="form-group">
              <label for="endTime">End Time:</label>
              <input type="time" id="endTime" value="00:00" formControlName="endtime" [(ngModel)]="interviewModel.endTime">
              <div class="error-messages" *ngIf="endTime.touched">
                <div *ngIf="!endTime.value">End Time can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <label for="venue">
                Venue: <br>
                <span class="seconday-label">(Physical Address or online link)</span>
              </label>
              <input type="text" id="venue" formControlName="venue" [(ngModel)]="interviewModel.venue">
              <div class="error-messages" *ngIf="venue.touched">
                <div *ngIf="venue.invalid && venue.dirty">Venue needs to be at least 3 characters </div>
                <div *ngIf="!venue.value">Venue can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <label for="cc">
                Cc<span class="seconday-label">(Optional)</span>: <br>
                <span class="seconday-label">(Add comma separated emails)</span>
              </label>
              <input type="text" id="cc" formControlName="cc" [(ngModel)]="interviewModel.cc">
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <label for="notes">
                Notes
                <span class="seconday-label">(optional)</span>:
              </label>
              <textarea name="notes" id="notes" formControlName="notes" [(ngModel)]="interviewModel.notes"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <button type="submit" class="button curved-button" (click)="sendInterviewInvite()">SEND</button>
            </div>
          </div>
        </form>
      </div>

      <!--      display only-->
      <div class="interview-form" *ngIf="isScheduled">
        <div class="row">
          <div class="form-group">
            <label for="startTime" id="startTimeLabel">Start Time:</label>
            {{interviewModel.startTime}}
          </div>
          <div class="form-group">
            <label for="endTime">End Time:</label>
            {{interviewModel.endTime}}
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <label for="venue">
              Venue: <br>
              <span class="seconday-label">(Physical Address or online link)</span>
            </label>
            {{interviewModel.venue}}
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <label for="cc">
              Cc<span class="seconday-label">(Optional)</span>: <br>
              <span class="seconday-label">(Add comma separated emails)</span>
            </label>
            {{interviewModel.cc}}
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <label for="notes">
              Notes
              <span class="seconday-label">(Optional)</span>:
            </label>
            {{interviewModel.notes}}
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <button class="button curved-button" (click)="editInterviewInvite()">Edit</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Manual Stage === 2 -->
    <div class="buttons" *ngIf="stageModel.stageType === 2">
      <button class="button curved-button decline" (click)="modalService.open('declineCandidate' + stageIndex)">
        Decline
      </button>
      <button class="button curved-button approve" (click)="modalService.open('movetoInterview' + stageIndex)">
        Approve
      </button>
    </div>
  </div>
</div>


<!-- Decline Candidate Modal Confirmation -->
<jw-modal id="declineCandidate{{stageIndex}}">
  <div class="modal-header-row">
    <div class="header">
      Decline Candidate Confirmation
    </div>
    <button class="close" (click)="modalService.close('declineCandidate' + stageIndex)">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>
  <div class="modal-body">
    <div class="message">
      Are you sure you want to Decline Candidate?
    </div>
    <button class="button curved-button delete-btn" (click)="disqualifyCandidate()">
      Decline Candidate
    </button>
  </div>
</jw-modal>

<!-- Move to interview Modal Confirmation -->
<jw-modal id="movetoInterview{{stageIndex}}">
  <div class="modal-header-row">
    <div class="header">
      Approval Confirmation
    </div>
    <button class="close" (click)="modalService.close('movetoInterview' + stageIndex)">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>
  <div class="modal-body">
    <div class="message">
      Are you sure you want to Move Candidate to the next stage?
    </div>
    <button class="button curved-button delete-btn" (click)="approveCandidate()">
      Approve
    </button>
  </div>
</jw-modal>

