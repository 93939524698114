import {BaseModel} from './Base.Model';

export class LocationModel extends BaseModel {

  lineone: string;
  linetwo: string;
  linethree: string;
  linefour: string;
  code: number;
  applicationId: string;
}
