import { Component, OnInit } from '@angular/core';
import {UserModel} from '../../Core/Models/UserModel';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public showMessage: boolean;
  users: UserModel[];
  user: UserModel;

  constructor() { }

  ngOnInit(): void {

    this.users = Array<UserModel>();
    for (let i = 1; i < 5; i++) {
      this.users.push(this.fakeUsers(i));
    }
  }

  editUser(id: string): void {

  }

  deleteUser(): void {

  }

  fakeUsers(i: number): UserModel {
    this.user = new UserModel();

    this.user.id = '';
    this.user.email = 'user@email.com' + i;
    this.user.roleNames = Array<string>();
    this.user.roleNames.push('Nice-Job-1');
    this.user.roleNames.push('Nice-Job-2');

    return this.user;
  }

}
