import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label, SingleDataSet} from 'ng2-charts';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';
import {StagesService} from '../../../../Services/StagesService/Stages/stages.service';
import {CandidatesService} from '../../../../Services/CandidatesService/candidates.service';
import {StagescandidatejobService} from '../../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {StageWithCountModel} from '../../../../Core/Models/StageWithCount.Model';

@Component({
  selector: 'app-application-statistics',
  templateUrl: './application-statistics.component.html',
  styleUrls: ['./application-statistics.component.scss']
})
export class ApplicationStatisticsComponent implements OnInit {

  @Input() applicationId: string;
  @Input() applicationTitle: string;
  @Input() stagesWithCount: StageWithCountModel[];

  filterCriteria: FilterCriteria;
  stageNames: string[];

  /* BarChart Settings */
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  public barChartData: ChartDataSets[] = [{
    data: [],
    label: 'Candidates Per Stage',
    barThickness: 70
    }
  ];

  barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{
        gridLines: {}
      }], yAxes: [{
        gridLines: {
          color: '#BAE3CF'
        }
      }]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
    legend: {display: false}
  };

  public barChartColors: Color[] = [
    { backgroundColor: '#47E1F4' }
  ];

  /* Doughnut Chart Settings */
  public doughnutChartLabels: Label[] = ['Qualified Candidates', 'Unqualified Candidates', 'Candidates in Progress'];
  doughnutChartDataChartColors: any[] = [];
  public doughnutChartData: SingleDataSet[] = [[350, 450, 100]];
  public doughnutChartType: ChartType = 'doughnut';
  donutChartOptions: ChartOptions = {
    responsive: true,

    legend: {position: 'bottom',
      align: 'center',
      labels: {
        boxWidth : 10
      }},
  };

  // Pie
  public pieChartLabels:string[] = ['Chrome', 'Safari', 'Firefox','Internet Explorer','Other'];
  public pieChartData:number[] = [40, 20, 20 , 10, 10];
  public pieChartType:string = 'pie';
  public pieChartDataChartColors: any[] = [];

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {position: 'right',
      align: 'center',
      labels: {
        boxWidth : 10
      }},
  };

  constructor(private stagesService: StagesService,
              private candidatesService: CandidatesService,
              private stageCandidateApplication: StagescandidatejobService) { }

  ngOnInit(): void {

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.CandidateKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys.push(this.applicationId);

    this.stageNames = Array<string>();

    this.patchStagesWithCount();
  }

  patchStagesWithCount(): void {
    for (let i = 0; i < this.stagesWithCount.length; i++) {
      this.fetchCandidatesPerStage(this.stagesWithCount[i]);
    }
  }

  fetchCandidatesPerStage(stageWithCount: StageWithCountModel): void {
    this.stageCandidateApplication.GetStageApplicants(stageWithCount.stageId).subscribe( res => {
      for (let i = 0; i < this.stagesWithCount.length; i++) {
        if (this.stagesWithCount[i].stageId === stageWithCount.stageId) {
          console.log(res.length);
          this.stagesWithCount[i].count = res.length;
          this.barChartLabels.push(this.stagesWithCount[i].name);
          this.barChartData[0].data.push(res.length);
        }
      }
    });
  }

  /* Events */
  public chartClicked( e: any ): void {
    console.log(e);
  }

  public chartHovered( e: any ): void {
    console.log(e);
  }
}
