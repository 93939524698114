import {Component, Input, OnInit} from '@angular/core';
import {StageModel} from '../../../../Core/Models/Stage.Model';
import {QuestionsAndAnswersService} from '../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {CandidateAnswerModel} from '../../../../Core/Models/CandidateAnswer.Model';
import {Guid} from 'guid-typescript';
import {DocumentUploadModel} from '../../../../Core/Models/DocumentUpload.Model';
import {QuestionModel} from '../../../../Core/Models/Question.Model';
import {StageTypeEnum} from '../../../../Core/Enums/StageType.Enum';
import {InterviewModel} from '../../../../Core/Models/Interview.Model';
import {ToasterNotificationService} from '../../../../Services/ToasterNotificationService/ToasterNotification.Service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InterviewService} from '../../../../Services/InterviewService/interview.service';
import {CandidateModel} from '../../../../Core/Models/CandidateModelModell.Model';
import {CandidatesService} from '../../../../Services/CandidatesService/candidates.service';
import {NotificationsService} from '../../../../Services/NotificationsService/Notifications.Service';
import {MailModel} from '../../../../Core/Models/Mail.Model';
import {EmailTypeEnum} from '../../../../Core/Enums/EmailType.Enum';
import {AuthenticationService} from '../../../../Services/AuthenticationService/authentication.service';
import {CandidateStageProgressModel} from '../../../../Core/Models/CandidateStageProgress.Model';
import {StagescandidatejobService} from '../../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {ModalService} from '../../../_modal';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';
import {QuestionTypes} from '../../../../Core/Enums/QuestionType.Enum';
import {DocumentModel} from '../../../../Core/Models/Document.Model';
import {StageProgress} from '../../../../Core/Enums/StageProgress.Model';


@Component({
  selector: 'app-load-candidate-answers',
  templateUrl: './load-candidate-answers.component.html',
  styleUrls: ['./load-candidate-answers.component.scss']
})
export class LoadCandidateAnswersComponent implements OnInit {

  @Input() stageModel: StageModel;
  @Input() candidateId: string;
  @Input() candidateName: string;
  @Input() candidateSurname: string;
  @Input() applicationId: string;
  @Input() applicationTitle: string;
  @Input() stageIndex: number;
  @Input() candidateDocuments: DocumentModel[];

  candidateAnswers: CandidateAnswerModel[];
  candidate: CandidateModel;
  doc: DocumentUploadModel;
  question: QuestionModel;
  manualStageType: StageTypeEnum.Manual;
  interviewModel: InterviewModel;
  isScheduled: boolean;
  interviewForm: FormGroup;
  sendCopy: boolean;
  questionModelArray: QuestionModel[];
  questionModelArrayTemp: QuestionModel[];
  stageProgress: CandidateStageProgressModel;
  filterCriteria: FilterCriteria;
  candidateAnswerModel: CandidateAnswerModel[];
  isStageProgressPresent: boolean;

  constructor(private questionsAndAnswersService: QuestionsAndAnswersService,
              private formBuilder: FormBuilder,
              private toasterNotificationService: ToasterNotificationService,
              private interviewService: InterviewService,
              private notificationsService: NotificationsService,
              private authenticationService: AuthenticationService,
              private candidateService: CandidatesService,
              private stagescandidatejobService: StagescandidatejobService,
              public modalService: ModalService) { }

  ngOnInit(): void {
    this.question = new QuestionModel();
    this.interviewModel = new InterviewModel();
    this.isScheduled = false;
    this.sendCopy = false;
    this.isStageProgressPresent = false;

    this.questionModelArray = Array<QuestionModel>();
    this.questionModelArrayTemp = Array<QuestionModel>();
    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.StageKeys = Array<string>();

    this.fetchAllQuestions(this.stageModel.id);
    this.initiateForm();
    this.fetchCandidate();

    if (this.stageModel.stageType === StageTypeEnum.Interview) {
      this.fetchScheduledInterview();
    }

    if (this.stageModel.stageType === StageTypeEnum.Manual) {
      this.stageProgress = new CandidateStageProgressModel();
      this.fetchStageProgressAndOutcome();
    }
    console.log(this.candidateDocuments);
  }

  initiateForm(): void {
    this.interviewForm = this.formBuilder.group({
      starttime: [this.interviewModel.startTime, [Validators.required]],
      endtime: [this.interviewModel.endTime, [Validators.required]],
      venue: [this.interviewModel.venue, [Validators.required]],
      cc: this.interviewModel.cc,
      notes: [this.interviewModel.notes]
    });
  }

  updateStageProgressAndOutcome(candidateStageProgressModel: CandidateStageProgressModel, approval: boolean): void {
    this.stagescandidatejobService.UpsertStageCandidateJob(candidateStageProgressModel).subscribe(response => {
      if (response) {
        if (approval) {
          this.toasterNotificationService.Success('Candidate Stage Approved');
        } else {
          this.toasterNotificationService.Success('Candidate Successfully Declined');
        }
      } else {
        this.toasterNotificationService.Failure('Something went wrong when trying to update stage progress. Please contact Hazie Support');
      }
      console.log(response);
    }, error => {
      console.log(error);
    });
  }

  fetchStageProgressAndOutcome(): void {
    this.stagescandidatejobService.
    GetStageCandidateJob(Guid.parse(this.candidateId), Guid.parse(this.stageModel.id)).subscribe(response => {
      if (response) {
        this.stageProgress = response;
        this.isStageProgressPresent = true;
      }

    }, error => {
      console.log(error);
    });
  }

  approveCandidate(): void {
    if (this.isStageProgressPresent) {
      this.stageProgress.isPromoted = true;
      this.stageProgress.progress = StageProgress.Complete;
      this.updateStageProgressAndOutcome(this.stageProgress, true);
      console.log('Approve!');
    } else {
      this.toasterNotificationService.Alert('Candidate has not yet Completed the Stage');
    }

    this.modalService.close('movetoInterview' + this.stageIndex);
  }

  disqualifyCandidate(): void {
    if (this.isStageProgressPresent) {
      this.stageProgress.isPromoted = false;
      this.stageProgress.progress = StageProgress.Complete;
      this.updateStageProgressAndOutcome(this.stageProgress, false);
      console.log('Decline!');
    } else {
      this.toasterNotificationService.Alert('Candidate has not yet Completed the Stage');
    }

    this.modalService.close('declineCandidate' + this.stageIndex);
  }

  sendInterviewInvite(): void {

    this.markFormFieldsAsTouched();

    if (this.interviewForm.valid) {
      this.interviewModel.candidateId = this.candidateId;
      this.interviewModel.stageId = this.stageModel.id;
      this.interviewModel.applicationId = this.applicationId;
      this.interviewModel.roleTitle = this.applicationTitle;
      this.interviewModel.isInvitedForInterview = true;

      if (this.interviewModel.date) {
        this.interviewService.Upsert(this.interviewModel).subscribe(response => {
          this.toasterNotificationService.Success('Interview Invite Send');
          this.sendInterviewInviteEmail();
          this.isScheduled = true;
        }, error => {
          console.log('Interview Invite Failed!');
          this.toasterNotificationService.Failure('Interview Invite Failed!');
        });
      } else {
        this.toasterNotificationService.Alert('Please select date for interview');
      }
    }
  }

  sendInterviewInviteEmail(): void {

    const mail = new MailModel();
    const emailType = EmailTypeEnum.ScheduleCandidateInterview;
    const user = this.authenticationService.getUserCredential().username;

    mail.sendCopy = this.sendCopy;
    mail.emailList = [this.candidate.email, user]; /*[candidate, admin]*/

    /* cater for cc email's */
    mail.emailList = this.appendCcEmailsToEmailList(mail.emailList);

    mail.name = this.candidateName;
    mail.surname = this.candidateSurname;
    mail.applicationId = this.applicationId.toString();
    mail.subject = this.applicationTitle + ' Interview for ' + this.candidateName + ' ' + this.candidateSurname;
    mail.message = this.interviewModel.notes;
    mail.emailType = emailType;
    mail.applicationName = this.applicationTitle;
    mail.startTime = this.interviewModel.startTime;
    mail.endTime = this.interviewModel.endTime;
    mail.venue = this.interviewModel.venue;
    mail.date = this.interviewModel.date;
    this.notificationsService.SendMail(mail).subscribe(response => {
      if (response === null) {

      }
    });
  }

  appendCcEmailsToEmailList(emailList: string[]): string[] {
    if (this.interviewModel.cc && this.interviewModel.cc.length > 0) {
      emailList = emailList.concat(this.interviewModel.cc.split(','));
      return emailList;
    } else {
      return emailList;
    }
  }

  editInterviewInvite(): void {
    this.isScheduled = false;
  }

  fetchScheduledInterview(): void {
    this.interviewService.
    checkIfCandidateHasAnInterview(Guid.parse(this.candidateId), Guid.parse(this.stageModel.id)).subscribe(response => {
      this.interviewModel = response;
      this.isScheduled = true;
    }, error => {
      console.log(error);
    });
  }

  /* NB: From child component */
  fetchInterviewModelWithDate(UpdatedInterviewModel: InterviewModel): void {
    this.interviewModel.date = UpdatedInterviewModel.date;
  }

  fetchCandidate(): void {
    this.candidateService.GetById(this.candidateId).subscribe(response => {
      this.candidate = response;
    });
  }

  fetchAllQuestions(id: string): void {
    this.filterCriteria.StageKeys.push(id);
    console.log('something atleast');
    console.log(this.filterCriteria);
    this.questionsAndAnswersService.GetByFilterCriteria(this.filterCriteria).subscribe(questions => {
      this.questionModelArrayTemp = questions;

      /* Filter/leave-out Deleted Questions */
      this.questionModelArray = this.questionModelArrayTemp.filter(question => question.isDeleted === false);
      console.log(this.questionModelArray);
      if (this.questionModelArray.length ===  0) {
        this.candidateAnswers = [];
        // this.showMessage = true;
      } else {
        if (this.stageModel.stageType === StageTypeEnum.Interview) {
          this.questionModelArray = [];
        }

        if (this.questionModelArray.length > 0) {
          this.fetchCandidateStageAnswers();
        } else {
          this.candidateAnswers = [];
        }
        // this.showMessage = false;
      }

    });
  }

  fetchCandidateStageAnswers(): void {
    this.questionsAndAnswersService.GetAllCandidateStageAnswers(this.candidateId, this.stageModel.id).subscribe(response => {
      this.candidateAnswers = response;
      console.log('fetching answers!');

      for (let i = 0; i < this.questionModelArray.length; i++) {
        for (let j = 0; j < this.candidateAnswers.length; j++) {
          if (this.questionModelArray[i].id === this.candidateAnswers[j].questionId) {
            this.candidateAnswers[j].question = this.questionModelArray[i].question;

            if (this.questionModelArray[i].questionType === QuestionTypes.Upload) {

            } else if (this.questionModelArray[i].questionType === QuestionTypes.Odyssey) {
              console.log(this.candidateAnswers[j].answer);
              if (this.candidateAnswers[j].answer) {
                console.log(this.candidateAnswers[j].answer);
                this.questionModelArray[i].candidateAnswer = this.candidateAnswers[j].answer;
              } else {
                this.questionModelArray[i].candidateAnswer = 'Awaiting Results';
              }

            }else {
              this.questionModelArray[i].candidateAnswer = this.candidateAnswers[j].answer;
            }
          }
        }

        /* Documents are a special case, that is why they are outside the inner loop */
        if (this.questionModelArray[i].questionType === QuestionTypes.Upload) {
          this.questionModelArray[i].candidateAnswer = this.getCorrectDocumentForQuestion(this.questionModelArray[i].question);
        }
      }

    });
  }

  getCorrectDocumentForQuestion(question: string): string {
    let link = '';
    this.candidateDocuments.forEach(document => {
      if (document.purpose === this.getDocumentPurpose(question)) {
        link = document.url;
      } else {
        console.log(document.purpose, this.getDocumentPurpose(question));
      }
    });
    return link;
  }

  getDocumentPurpose(questionText: string): string {
    let purposeFound = 'Not Found!';
    const purposeList = [
      'certified ID',
      'uncertified ID',
      'Id',
      'Grade 10 Results',
      'Grade 11 Results',
      'Grade 12 Results',
      'Application Form',
      'Mid-Year Results',
      'Mid Year Results',
      'Final Year Results',
      'First Year Results',
      'Second Year Results',
      'Third Year Results',
      'Fourth Year Results',
      'NBT',
      'Banking Details',
      'Certified Qualification',
      'Qualification',
      'CV',
      'Tax',
      'Affidavit',
      'residential',
      'Proof of payment'
    ];

    const lowerCaseQuestion = questionText.trim().toLowerCase();

    purposeList.forEach( purpose => {
      if (lowerCaseQuestion.includes(purpose.toLowerCase())) {
        // if (purpose.toLowerCase().includes('application form')) {
        //   purposeFound = purpose.toLowerCase() + ' ' + this.question.applicationId;
        // } else {
        //   purposeFound = purpose.toLowerCase();
        // }
        purposeFound = purpose.toLowerCase();
        console.log(purpose);
        return ;
      }
    });

    return purposeFound;
  }

  markFormFieldsAsTouched(): void {
    this.startTime.markAsTouched();
    this.endTime.markAsTouched();
    this.venue.markAsTouched();
    this.notes.markAsTouched();
    this.cc.markAsTouched();
  }

  get startTime(): AbstractControl {
    return this.interviewForm.get('starttime');
  }

  get endTime(): AbstractControl {
    return this.interviewForm.get('endtime');
  }

  get venue(): AbstractControl {
    return this.interviewForm.get('venue');
  }

  get notes(): AbstractControl {
    return this.interviewForm.get('notes');
  }

  get cc(): AbstractControl {
    return this.interviewForm.get('cc');
  }
}
