<app-navbar></app-navbar>
<div class="main-container">
  <h5 class="heading">{{applicationTitle}}</h5>
<!--  <div class="stats-header">


    <div class="stats-body-left">
      <div class="candidate-left">
        <img  src="../../../../assets/icon/user.svg" alt="">
      </div>

      <div class="candidate-right">
        eeehfqetve
      </div>
    </div>
    <div class="stats-body-right">
      <div class="left">
        <img  src="../../../../assets/icon/user.svg" alt="">
      </div>


      <div class="right">
        eee
      </div>
    </div>


  </div>-->

  <div class="header-stats">
    <div class="box">
      <div class="image">
        <img  class="image-size" src="../../../../assets/icons/user.svg" alt="">
      </div>
      <div class="info">
        <small class="text-number">{{candidateCount}}</small>
        <small class="text-word">Candidates</small>
        <button type="button" class="button" (click)="goToCandidatesList()">View</button>
      </div>
    </div>
    <div class="box">

      <div class="image">
        <img class="image-size" src="../../../../assets/icon/equalizer.svg" alt="">
      </div>
      <div class="info">
        <small class="text-number">{{stageCount}}</small>
        <small class="text-word">Stages</small>
        <button type="button" class="button" (click)="goToApplicationStages()">View</button>
      </div>
    </div>



  </div>
  <div class="main-stats">
    <app-application-statistics *ngIf="stagesWithCount.length"
      [applicationId]="applicationId"
      [applicationTitle]="applicationTitle"
      [stagesWithCount]="stagesWithCount"
    ></app-application-statistics>
    <div class="no-stages" *ngIf="!stagesWithCount.length">
      {{applicationTitle}} has no stages.
    </div>
  </div>

</div>
