import {BaseModel} from './Base.Model';
import {LocationModel} from './Location.Model';
import {categoryEnum} from "../Enums/Category.Enum";

export enum Industry {
  InformationTechnology = 1,
  Engineering,
  Administration,
  Manufacturing,
  HealthCare,
  Finance,
  Retail,
  Accounting,
  Construction,
  Media
}

export class ApplicationModel extends BaseModel {
  title: string;
  shortDescription: string;
  longDescription: string;
  salary: number;
  isDeleted: boolean;
  availableDate: Date;
  expiryDate: Date;
  isActive: boolean;
  dateCreated: Date;
  deletedDate: Date;
  location: LocationModel;
  industry: Industry;
  category: categoryEnum;
  companyId: string;
  orderBy: number;
  logoImage: string;
  bannerImage: string;
}
