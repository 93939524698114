import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {StagesService} from '../../../Services/StagesService/Stages/stages.service';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {DocumentModel} from '../../../Core/Models/Document.Model';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';

@Component({
  selector: 'app-candidates-stages',
  templateUrl: './candidates-stages.component.html',
  styleUrls: ['./candidates-stages.component.scss']
})
export class CandidatesStagesComponent implements OnInit {

  stageObjects: StageModel[];
  stage: StageModel;
  candidateId: string;
  applicationTitle: string;
  applicationId: string;
  candidateName: string;
  candidateSurname: string;
  filterCriteria: FilterCriteria;
  showMessage: boolean;
  candidateDocuments: DocumentModel[];

  constructor(private activatedRoute: ActivatedRoute,
              private stageService: StagesService,
              private documentsService: DocumentsService) { }

  ngOnInit(): void {

    this.candidateId  = this.activatedRoute.snapshot.paramMap.get('id');
    this.applicationTitle = this.activatedRoute.snapshot.queryParamMap.get('title');
    this.applicationId = this.activatedRoute.snapshot.queryParamMap.get('applicationId');
    this.candidateName = this.activatedRoute.snapshot.queryParamMap.get('candidateName');
    this.candidateSurname = this.activatedRoute.snapshot.queryParamMap.get('candidateSurname');

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.StageKeys = Array<string>();
    this.candidateDocuments = Array<DocumentModel>();

    this.showMessage = false;
    this.stageObjects = new Array<StageModel>();

    this.getApplicationStages(this.applicationId);
    this.getCandidateDocuments(this.candidateId);
  }

  getApplicationStages(id: string): void {
    this.filterCriteria.ApplicationsKeys.push(id);
    this.stageService.GetByFilterCriteria(this.filterCriteria).subscribe(stages => {
      this.stageObjects = stages.filter(stage => stage.isDeleted === false);

      if (this.stageObjects.length ===  0) {
        this.showMessage = true;
      }
    });
  }

  getCandidateDocuments(candidateId: string): void {
    this.documentsService.GetDocumentsInfoList(candidateId).subscribe(response => {
      this.candidateDocuments = response;
    }, error => {
      console.log(error);
    });
  }

}
