import {NotificationsService} from 'angular2-notifications';
import {Injectable} from '@angular/core';

@Injectable()
export class ToasterNotificationService {
  constructor(private notification: NotificationsService) {
  }

  Success(message: string): void {
    this.notification.success('Success', message, {
      position: ['bottom', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  Failure(message: string): void {
    this.notification.error('Error', message, {
      position: ['bottom', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  Alert(message: string): void {
    this.notification.alert('Alert', message, {
      position: ['bottom', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }
}
