import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserDataService} from '../user-data.service';
import {RoleService} from '../../../../Services/RoleServices/Role.Service';
import {isArray} from 'util';
import {Router} from '@angular/router';
import {UserModel} from '../../../../Core/Models/UserModel';
import {AuthenticationService} from '../../../../Services/AuthenticationService/authentication.service';
import {ApplicationsService} from '../../../../Services/ApplicationsService/Applications.Service';
import {UserService} from '../../../../Services/UserServices/User.Service';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.scss'],
  providers: [UserDataService]
})
export class UserGridComponent implements OnInit {
  items: UserModel [];
  isAdmin = false;
  isloaded = false;
  constructor(private userService: UserService,
              private roleService: RoleService,
              private router: Router,
              private jobsService: ApplicationsService,
              private authenticationService: AuthenticationService,
              private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getAllUsers();
    this.validateUser();
  }

  validateUser(): void {
    const credential =  this.authenticationService.getUserCredential();
    console.log(credential);
    this.roleService.GetUserRoles(credential.username).subscribe(x => {
      if (isArray(x)) {
        x.forEach(y => {
          if (y === 'Admin') {
            this.isAdmin = true;
          }
        });
      } else {
        if (x === 'Admin') {
          this.isAdmin = true;
        }
      }
      if (!this.isAdmin) {
        this.router.navigate(['landing-page']);
      }
    });
}

// Fetch all users from identity server via api
  getAllUsers(): void {
    this.userService.GetUser().subscribe(data => {
      this.items = data;
      this.items.forEach(x => {
        this.roleService.GetUserRoles(x.email)
          .subscribe(r => {

            const filter = new FilterCriteria();
            filter.CompanyKeys = ['1f5f7eb5-734f-b345-ca11-5f75b9286328']; // Old Mutual Key
            this.jobsService.GetByFilterCriteria(filter).subscribe(me => {
              const list = [];
              r.forEach( i => {
                const result = me.filter(f => f.id === i);
                if (result.length > 0) {
                  list.push(result[0].title);
                }
              });
              x.roleNames = list;
              this.isloaded = true;
              this.cdr.detectChanges();
            });
          });
      });
    }, error => console.log(error));
  }
// Delete a user from itentity server and refresh users list on the page
  delete(item): void {
  }
}
