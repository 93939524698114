import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRegistrationModel} from '../../../Core/Models/UserRegistration.Model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  disablePassword: boolean;
  enableAD: boolean;
  registerForm: FormGroup;
  // username: FormControl;
  // confirmPassword: FormControl;
  useAD: boolean;
  errorMessages: string[];
  submitted = false;
  public applicationId;
  user: UserRegistrationModel;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder) {

    this.user = new UserRegistrationModel();


    this.applicationId = this.route.snapshot.queryParams.JobId;

    this.disablePassword = this.authenticationService.forceADUserRegistration;

    this.useAD = this.authenticationService.allowADUserRegistration;

  }

  ngOnInit(): void {
    this.initiateForm();
  }

  initiateForm(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['',
        [Validators.required,
          Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      // username: this.username,
      // confirmPassword: this.confirmPassword,
    });
  }


  register(): void {

    this.markFormFieldsAsTouched();
    console.log(this.registerForm);

    if (this.registerForm.valid) {
      this.errorMessages = [];
      this.user.ClientId = this.authenticationService.ClientId;
      this.user.Email = this.email.value;
      this.user.LastName = this.lastName.value;
      this.user.FirstName = this.firstName.value;
      this.user.UseAD = this.useAD;
      this.user.Password = this.password.value;
      // this.user.ConfirmPassword = this.confirmPassword.value;
      // this.user.Username = this.username.value;
      this.user.IsDashboard = true;
      this.user.ReturnUrl = this.authenticationService.ReturnUrl;

      this.authenticationService.registration( this.user).subscribe( data => {

        localStorage.setItem('verify', 'register');
        this.router.navigate(['./verifyUser']);

      }, response => {
        this.submitted = true;
        this.errorMessages.push('Failed to complete registration.');

        // this.formFields.password.setErrors({incorrect: true});
      });
    }
  }

  markFormFieldsAsTouched(): void {
    this.firstName.markAsTouched();
    this.lastName.markAsTouched();
    this.email.markAsTouched();
    this.password.markAsTouched();
  }

  /* -RegisterForm Getters- */
  get firstName(): AbstractControl {
    return this.registerForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.registerForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.registerForm.get('email');
  }

  get password(): AbstractControl {
    return this.registerForm.get('password');
  }

}
