import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {TopApplicationCountModel} from '../../Core/Models/TopApplicationCount.Model';
import {Injectable} from '@angular/core';

@Injectable()
export class TopApplicationCountService {

  baseUrl = environment.baseUrl;
  TopApplicationCountEndpoints = `${this.baseUrl}/TopApplicationCount/`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: string): Observable<TopApplicationCountModel> {
    return this.httpClient.get<TopApplicationCountModel>(`${this.TopApplicationCountEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<TopApplicationCountModel[]> {
    return this.httpClient.post<TopApplicationCountModel[]>(`${this.TopApplicationCountEndpoints}all`, filterCriteria);
  }

  AllCount(filterCriteria: FilterCriteria): Observable<number> {
    return this.httpClient.post<number>(`${this.TopApplicationCountEndpoints}AllCount`, filterCriteria);
  }

  Upsert(model: TopApplicationCountModel): Observable<string> {
    return this.httpClient.post<string>(`${this.TopApplicationCountEndpoints}Upsert`, model);
  }
}
