<div id="content-page">
  <div class="card">
    <div class="card-header page-title">
      Manage Roles
    </div>

    <div class="card-body card-body-full-screen">

      <fieldset>
        <legend>Roles</legend>
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let item of items">
              <td>{{item.name}}</td>
              <td>
                <button routerLink="/roleCapture" class="btn btn-outline-secondary btn-sm fa fa-trash-o"> View Assigned Users</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </fieldset>

    </div>
  </div>
</div>


