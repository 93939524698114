<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a routerLink="/login">
        <img  class="logo" src="../../../../assets/logos/OM_Dashboad_logo.png" alt="Card image cap">
      </a>
    </div>
    <div class="powered-by-hazie">
      <a href="https://hazie.co.za" target="_blank">
        <img  class="logo" src="../../../../assets/logos/powered-by-hazie-color-transparent.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="card-header">
        <div class="company-logo">
          <a routerLink="/login">
            <img  class="logo" src="../../../../assets/logos/OM_Dashboad_logo.png" alt="Card image cap">
          </a>
        </div>

        <div class="header-text">
          <h1 *ngIf="isSuccess">CONGRADULATIONS!</h1>
          <h1 *ngIf="!isSuccess">Please Wait While we Validate Your Email!</h1>
          <small *ngIf="isSuccess">You have successfully verified your account. Please proceed to sign-in..</small>
        </div>
      </div>

      <div class="card-body">
        <div class="form-container">
          <form>
            <input type="submit" routerLink="/login" value="Take me home">
          </form>
        </div>
      </div>

      <div class="card-footer">
      </div>
    </div>
  </div>
</div>
