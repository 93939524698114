import {BaseModel} from './Base.Model';
import {EmailTypeEnum} from '../Enums/EmailType.Enum';

export class MailModel extends BaseModel{
  emailList: string[];
  emailType: EmailTypeEnum;
  name: string;
  surname: string;
  applicationName: string;
  applicationId: string;
  attachment: boolean;
  link: string;
  contactPerson: string;
  contactNumber: string;
  datetime: Date;
  address: string;
  venue: string;
  time: string;
  startTime: string;
  endTime: string;
  date: string;
  subject: string;
  message: string;
  sendCopy: boolean;
}
