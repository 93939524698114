import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserItemModel} from '../user-item.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserDataService} from '../user-data.service';
import {RoleService} from '../../../../Services/RoleServices/Role.Service';
import {RoleGridItemModel} from '../../roles/role-grid/role-grid-item.model';
import {ApplicationsService} from '../../../../Services/ApplicationsService/Applications.Service';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';
import {ToasterNotificationService} from '../../../../Services/ToasterNotificationService/ToasterNotification.Service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  providers: [UserDataService]
})
export class UserEditComponent implements OnInit {
  model: UserItemModel;
  editForm: FormGroup;
  items: RoleGridItemModel[];
  username: FormControl;
  isAuthorised: FormControl;
  email: string;

  config: any;
  term: any;
  removePagination = true;

  constructor(private roleService: RoleService, private route: ActivatedRoute, private router: Router,
              private applicationsService: ApplicationsService,
              private notification: ToasterNotificationService) {
  }

  ngOnInit() {
    const email =  this.route.snapshot.params.email;
    console.log(email);
    this.route.params.subscribe(params => {
       this.email = params.email;
       console.log("Emila");
       console.log(this.email);
       this.loadEntity(this.email);
    });

    const filter = new FilterCriteria();
    filter.CompanyKeys = ['1f5f7eb5-734f-b345-ca11-5f75b9286328'];
    this.items = [];
    this.applicationsService.GetByFilterCriteria(filter).subscribe(data => {
      data.forEach(x => {
        this.roleService.AddRole(x.id).subscribe( u => {
          console.log('success');
        }, error => console.log('failed'));
        const role = new RoleGridItemModel();
        role.id = x.id;
        role.name = x.title;
        this.items.push(role);
        this.items.forEach(y => {
          this.checkIfRoleIsAuthorised(y);
        });

        this.config = {
          itemsPerPage: 7,
          currentPage: 1,
          totalItems: this.items.length
        };

        if (this.items.length >= 5) {
          this.removePagination = false;
        }

      });
    });


    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.isAuthorised = new FormControl(false);
  }

  createForm() {
    this.editForm = new FormGroup({
      isAuthorised: this.isAuthorised
    });
  }

  // load a user from identity server using the id in the query string
  loadEntity(id: any) {
    this.roleService.GetUserRoles(id).subscribe(x => {
      this.model = new UserItemModel();
      this.model.username = id;
      this.model.roleNames = x;
    });
  }

  // Save a user to identity server
  save() {
    this.notification.Success('Settings Saved');
    this.router.navigate(['users-list']);
  }

  setIsRoleAuthorised(name: any) {
    const exists = this.model.roleNames.filter(x => x === name);
    if (exists.length > 0) {
      this.roleService.DelUserFromRole(name, this.email)
        .subscribe(x => {
          console.log('Success');
          this.notification.Success('User Removed from Role');
        });
      this.model.roleNames = this.model.roleNames.filter(x => x !== name);
    } else {
      this.roleService.AddUserToRole(name, this.email).subscribe(x => {
        console.log('Success');
        this.notification.Success('User Added to Role');
      });
      this.model.roleNames.push(name);
    }
  }

  checkIfRoleIsAuthorised(item: RoleGridItemModel) {
    console.log(item);
    this.model.roleNames.forEach(x => {
      if (x === item.id) {
        if (item.isLoaded == null) {
          item.isLoaded = true;
        } else {
        }
        console.log(this.items);
        return item.isLoaded;
      }
    });
  }

  pageChanged(event): void {
    this.config.currentPage = event;
  }
}
