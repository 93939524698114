<app-navbar></app-navbar>
<div class="main-container">
  <div class="title">
    {{applicationTitle}}
  </div>

  <div class="message" *ngIf="candidateIds.length === 0" >
    No Candidates found for {{applicationTitle}}
  </div>

  <nav *ngIf="!showMessage">
    <ul *ngFor="let stage of stagesWithCount">
      <button type="button" class="button nav-button" class="{{getActive(stage.name)}}" (click)="setActive(stage.name)">
        <span class="green">&nbsp;&nbsp;</span>
<!--        {{stage.count}}-->
        {{stage.name}}
      </button>
    </ul>
  </nav>

  <div class="table">
    <div *ngIf="!showMessage">
      <div class="table-head" *ngIf="!noStageCandidates">
        <div class="table-cell">User</div>
        <div class="table-cell">Name</div>
        <div class="table-cell">Date Applied</div>
        <div class="table-cell">Action</div>
        <div class="table-cell">
<!--          <img class="image-export" src="../../../../assets/icons/export.svg" alt="">-->

<!--          <select [(ngModel)]="this.dropDownName" (ngModelChange)="exportByStage()"-->
<!--                  [ngModelOptions]="{standalone: true}">-->

<!--            <option *ngFor="let name of this.stageNames" [ngValue]="name">{{name}}</option>-->
<!--          </select>-->
          <img class="image-export" src="../../../../assets/icons/export.svg" alt="Export" title="Export" (click)="triggerExport('All')">
<!--          <button class="button" type="button" (click)="triggerExport('All')">Export</button>-->
<!--          <div class="dropdown">-->
<!--            <button type="button" class="button">Export &nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i>-->
<!--            </button>-->
<!--            <div class="dropdown-content">-->
<!--              <a (click)="triggerExport('All')">All</a>-->
<!--              <a *ngFor="let stage of stages" (click)="triggerExport(stage)">{{stage.name}}</a>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>

      <div class="table-body">
        <div class="message" *ngIf="showMessage">
          No Candidates found for {{applicationTitle}}
        </div>
        <div class="message" *ngIf="noStageCandidates">
          {{currentChoice}} has no candidates
        </div>
        <div class="message" *ngIf="noSearchResults && term && term !== ''">
          {{term}} not found
        </div>
        <div *ngIf="!showMessage">
          <div class="table"
               *ngFor="let candidate of candidateListFilter | filter:term | paginate: config; let i = index">
            <div class="table-row">
              <div class="table-cell">
                <img src="{{getCorrectProfileIcon(candidate)}}" alt="gender icon">
              </div>
              <div class="table-cell">{{candidate.name}} {{candidate.surname}}</div>
              <div class="table-cell">{{candidate.applieddate | date:'longDate'}} </div>
              <div class="table-cell">
                <div class="dropdown">
                  <button type="button" class="button">Manage &nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i>
                  </button>
                  <div class="dropdown-content">
                    <a (click)="goToCandidateProfile(candidate)">View Profile</a>
                    <a (click)="goToCandidateStages(candidate)">View Stages</a>
                  </div>
                </div>
              </div>
              <div class="table-cell">
                <a (click)="modalService.open('send-email')" title="Send Email" (click)="resetModal(candidate)">
                  <img src="../../../../assets/icons/chat.svg" alt="chat icon">
                </a>
              </div>
            </div>
            <div class="svg-stages">
              <app-stages-svg
                [candidateId]="candidate.id"
                [applicationId]="applicationId"
                [stages]="stages"
                (returnStages)="getCandidateStages($event)"
              ></app-stages-svg>
            </div>
          </div>

          <div class="pagination">
            <div class="TM.Pagination" *ngIf="candidateList.length > 0  && removePagination === false">
              <pagination-controls style="text-align: center" (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>


<!-- -Send-Mail-Modal- -->
<jw-modal id="send-email">
  <div class="header-row">
    <div class="header">
      Send Email
    </div>
    <button class="close" (click)="modalService.close('send-email')">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>
  <div class="modal-body" *ngIf="!mailSend">
    <form [formGroup]="mailForm" (ngSubmit)="sendEmail(currentCandidate)">
      <div class="form-group">
        <label for="subject">Subject:</label>
        <input type="text" class="form-control" formControlName="subject" id="subject">
        <div class="error-messages" *ngIf="subject.touched">
          <div *ngIf="subject.invalid && subject.dirty">* Subject needs to be at least 3 characters </div>
          <div *ngIf="!subject.value">* Subject can't be blank</div>
        </div>
      </div>

      <div class="form-group">
        <label for="message-box">Message</label>
        <editor
          id="message-box"
          tagName="textarea"
          apiKey="no-api-key"
          formControlName="message"
          toolbar="formatselect | bold italic underline | link bullist numlist indent outdent  | forecolor backcolor  "
          [init]="{
          base_url: '/tinymce',
          suffix: '.min', menubar: false,
          plugins: 'link lists formats' ,
          height: '277px'
           }"
          initialValue="Type Here">
        </editor>
        <div class="error-messages" *ngIf="message.touched">
          <div *ngIf="message.invalid && message.dirty">* Message needs to be at least 3 characters </div>
          <div *ngIf="!message.value">* Message can't be blank</div>
        </div>
        <br>
        <input type="checkbox" aria-label="Checkbox for following text input" formControlName="checkbox" id="send-me-a-copy-checkbox">
        <small class="ml-1">Send me a copy</small>
        <hr class="hr-line">
        <button class="button curved-button" type="submit">Send Email</button>
      </div>
    </form>

  </div>
  <div class="modal-body" *ngIf="mailSend">
    <div class="mail-send-confirmation">
      <img src="../../../../assets/icons/blue%20tick.svg" alt="">
      <button type="button" class="button curved-button" (click)="onOut()">Close</button>
    </div>
  </div>
</jw-modal>
