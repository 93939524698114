<app-navbar></app-navbar>
<div class="main-container">
  <div class="row">

    <div class="card">
      <img src="{{getCorrectProfileIcon(candidate)}}" alt="gender icon">
      <div class="candidate-info">
        <strong>Name: </strong> {{candidate.name}} {{candidate.surname}}<br>
        <strong>ID Number: </strong>{{candidate.identityNumber}} <br>
        <strong>Country of Birth: </strong> {{candidate.countryOfBirth}} <br>
        <strong>Email Address: </strong> {{candidate.email}}
      </div>
    </div>

    <div class="applications">
      <div class="title">
        Candidate's Applications
      </div>

      <div class="message" *ngIf="showMessage">
        No Applications found
      </div>
      <div class="tabs" *ngIf="!showMessage">
        <div class="tab" *ngFor="let application of applications; let i = index">
          <input type="checkbox" id="check{{i}}">
          <label for="check{{i}}" class="tab-label">
            <span class="activity-indicators-row"
                  [class.active]="application.isActive === true"
                  [class.in-active]="application.isActive === false">&nbsp;</span>{{application.title}}</label>
          <div class="tab-content" id="application{{i}}">
            <button (click)="goToCandidateStages(candidate, application)" type="button" class="button curved-button">View Application Stages</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
