import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {ConfirmModel} from '../../../Core/Models/Confirm.Model';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {

  title = 'Error';
  verificationId;
  code;
  isSuccess = false;
  confirm: ConfirmModel;

  constructor( private router: Router, private authenticationService: AuthenticationService,
               private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.confirm = new ConfirmModel();

    this.verificationId = this.route.snapshot.queryParams.id;
    this.code = this.route.snapshot.queryParams.code;

    this.confirm.id = this.verificationId;
    this.confirm.code = this.code;
    if (!this.verificationId) {
      console.log('verification id is invalid/missing');
      // this.router.navigate(['./error']);
    }
    this.verify();
  }

  verify() {
   this.authenticationService.confirmation(this.confirm).subscribe(data => {
      console.log('email verification successful');
      this.isSuccess = true;
    }, response => {
      console.log('unsuccessful email verification');
      // this.router.navigate(['./error']);
    });
  }

}
