import {Time} from '@angular/common';
import {BaseModel} from './Base.Model';
import {Guid} from 'guid-typescript';

export class InterviewModel extends BaseModel {
  candidateId: string;
  applicationId: string;
  stageId: string;
  date: string;
  startTime: any;
  endTime: any;
  roleTitle: string;
  venue: string;
  cc: string;
  name: string;
  notes: string;
  isInvitedForInterview: boolean;
}
