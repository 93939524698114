import {AuthorizedRoleModel} from './authorized-role.model';

export class UserItemModel {
  id: any;
  username: string;
  firstName: boolean;
  lastName: boolean;
  email: string[];
  isAccountClosed: boolean;
  isAccountVerified: boolean;
  roles: any [];
  roleNames: any [];
  authorizedRoles: AuthorizedRoleModel [] = [];
}
