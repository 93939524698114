import {Component, OnInit} from '@angular/core';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {ActivatedRoute} from '@angular/router';
import {StagesService} from '../../../Services/StagesService/Stages/stages.service';
import {ModalService} from '../../_modal';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {ToasterNotificationService} from '../../../Services/ToasterNotificationService/ToasterNotification.Service';
import {QuestionModel} from '../../../Core/Models/Question.Model';
import {AnswerModel} from '../../../Core/Models/Answer.Model';
import {OptionTypes} from '../../../Core/Enums/OptionType.Enum';
import {QuestionsAndAnswersService} from '../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {QuestionTypes} from '../../../Core/Enums/QuestionType.Enum';
import {StageTypeEnum} from '../../../Core/Enums/StageType.Enum';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-applications-stages',
  templateUrl: './applications-stages.component.html',
  styleUrls: ['./applications-stages.component.scss']
})
export class ApplicationsStagesComponent implements OnInit {

  stageObjects: StageModel[];
  stage: StageModel;
  stageToDelete: StageModel;
  stageToUpdate: StageModel;
  applicationId: string;
  applicationTitle: string;
  stageForm: FormGroup;
  showMessage: boolean;
  filterCriteria: FilterCriteria;
  questionModel: QuestionModel;
  isNewApplication: boolean;
  stageObjectscopy: StageModel[];


  constructor(private activatedRoute: ActivatedRoute,
              private stageService: StagesService,
              public modalService: ModalService,
              public formBuilder: FormBuilder,
              public notification: ToasterNotificationService,
              private questionsAndAnswersService: QuestionsAndAnswersService) { }

  ngOnInit(): void {

    this.applicationId  = this.activatedRoute.snapshot.paramMap.get('id');
    this.applicationTitle = this.activatedRoute.snapshot.queryParamMap.get('title');

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.StageKeys = Array<string>();

    this.showMessage = false;
    this.stageObjects = Array<StageModel>();
    this.stageObjectscopy = Array<StageModel>();

    this.getApplicationStages(this.applicationId);
    this.initiateForm();

  }

  getApplicationStages(id: string): void {
    this.filterCriteria.ApplicationsKeys.push(id);
    this.stageService.GetByFilterCriteria(this.filterCriteria).subscribe(stages => {
      this.stageObjects = stages.filter(stage => stage.isDeleted === false);
      this.stageObjectscopy = stages.filter(stage => stage.isDeleted === false);

      if (this.stageObjects.length ===  0) {
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
    });
  }

  createStage(): void {

    this.markFormFieldsAsTouched();

    if (this.stageForm.valid) {
      this.stage = new StageModel();

      this.stage.name = this.stageForm.controls.stageName.value;
      this.stage.stageType = +this.stageForm.controls.stageType.value;
      this.stage.isScoring = this.convertStringToBoolean(this.stageForm.controls.scoring.value);
      this.stage.applicationId = this.applicationId;
      this.stage.isActive = true;
      if (this.stage.isScoring) {
        this.stage.skippable = false;
      } else {
        this.stage.skippable = this.convertStringToBoolean(this.stageForm.controls.skippable.value);
      }

      console.log(this.stage);
      this.stageService.Upsert(this.stage).subscribe(response => {

        if (this.stage.stageType === StageTypeEnum.Interview) {
          this.addInterviewQuestion();
        }

        if (this.stage.stageType === StageTypeEnum.Odyssey) {
          this.addOdysseyQuestion();
        }

        this.notification.Success('Stage Created');
        this.getApplicationStages(this.applicationId);
      }, error => {
        this.notification.Failure('Stage Creation Unsuccessful, please double check the form');
      });
      this.modalService.close('modal-1');
    }

  }

  updateStage(): void {
    if (this.stageForm.valid) {
      /* Update stage from form info */
      this.stageToUpdate.name = this.stageName.value;
      this.stageToUpdate.stageType = this.stageType.value;
      this.stageToUpdate.isScoring = this.scoring.value;
      this.stageToUpdate.skippable = this.skippable.value;

      this.stageService.Upsert(this.stageToUpdate).subscribe(response => {
        this.notification.Success('Stage Updated');
      }, error => {
        this.notification.Failure('Stage Update Failed, Please contact support');
      });
      this.modalService.close('modal-1');
    }
  }

  addInterviewQuestion(): void {

    this.questionModel = new QuestionModel();
    this.questionModel.answer = new AnswerModel();
    this.questionModel.dateCreated = new Date();
    this.questionModel.question = 'Interview Related Question';
    this.questionModel.answer.answer = 'Interview Related Answer';
    this.questionModel.answer.questionId = this.questionModel.id;
    this.questionModel.questionType = QuestionTypes.Interview;
    this.questionModel.optionType = OptionTypes.Question;
    this.questionModel.optionsSelect = '';
    this.questionModel.optionsMultiSelect = '';
    this.questionModel.isActive = true;
    this.questionModel.stageId = this.stage.id;
    this.questionModel.isDeleted = false;
    this.questionModel.applicationId = this.applicationId;

    this.questionsAndAnswersService.Upsert(this.questionModel).subscribe( response => {
      console.log('question upsert working');
      this.getApplicationStages(this.applicationId);
    });
  }

  addOdysseyQuestion(): void {

    this.questionModel = new QuestionModel();
    this.questionModel.answer = new AnswerModel();
    this.questionModel.dateCreated = new Date();
    this.questionModel.question = 'Odyssey Assessment';
    this.questionModel.answer.answer = 'Odyssey Assessment Answer';
    this.questionModel.answer.questionId = this.questionModel.id;
    this.questionModel.questionType = QuestionTypes.Odyssey;
    this.questionModel.optionType = OptionTypes.Question;
    this.questionModel.optionsSelect = '';
    this.questionModel.optionsMultiSelect = '';
    this.questionModel.isActive = true;
    this.questionModel.stageId = this.stage.id;
    this.questionModel.isDeleted = false;
    this.questionModel.applicationId = this.applicationId;

    this.questionsAndAnswersService.Upsert(this.questionModel).subscribe( response => {
      console.log('question upsert working');
      this.getApplicationStages(this.applicationId);
    });
  }

  initiateForm(): void {
    this.stageForm = this.formBuilder.group({
      stageName: ['', [Validators.required, Validators.minLength(3)]],
      stageType: ['', [Validators.required]],
      scoring: ['', [Validators.required]],
      skippable: ['', [Validators.required]]
    });

    this.stageForm.valueChanges.subscribe(console.log);
  }

  prefillStageFormForEditing(stage: StageModel): void {
    this.stageForm = this.formBuilder.group({
      stageName: stage.name,
      stageType: stage.stageType,
      scoring: stage.isScoring,
      skippable: stage.skippable
    });
  }

  openCreateStageForm(): void {
    this.isNewApplication = true;
    this.initiateForm();
    this.modalService.open('modal-1');
  }

  openUpdateStageForm(index: any): void {
    this.isNewApplication = false; /* this allows us to re-use the stageForm */
    this.stageToUpdate = this.stageObjects[index];
    this.prefillStageFormForEditing(this.stageToUpdate);
    this.modalService.open('modal-1');
  }

  convertBooleanToString(isActive: boolean): string {
    if (isActive) {
      return 'Yes';
    }
    return 'No';
  }

  convertStringToBoolean(status: string): boolean {
    if (status === 'true') {
      return true;
    }
    return false;
  }

  markFormFieldsAsTouched(): void {
    this.stageName.markAsTouched();
    this.stageType.markAsTouched();
    this.scoring.markAsTouched();
    this.skippable.markAsTouched();
    this.skippableSpecialCase();
    this.odysseyStageSpecialCase();
  }

  skippableSpecialCase(): void {
    if (this.scoring.value === 'true') {
      this.stageForm.get('skippable').setValue('false');
    }
  }

  odysseyStageSpecialCase(): void {
    if (this.stageType.value === '3') {
      this.stageForm.get('skippable').setValue('true');
      this.stageForm.get('scoring').setValue('false');
    }
  }

  softDeleteStage(event: any): void {
    if (this.stageObjects.length > 0) {
      this.stageToDelete = new StageModel();
      this.stageToDelete = this.stageObjects[event];
      this.stageToDelete.isDeleted = true;

      this.stageObjects.splice(event, 1);

      this.stageService.Upsert(this.stageToDelete).subscribe(response => {
        this.notification.Success(this.stageToDelete.name + ' deleted successfully');
      }, error => {
        this.notification.Failure('Something went wrong, please contact the technical team.');
      });
    } else {

    }
  }

  /* StageForm Getter's */

  get stageName(): AbstractControl {
    return this.stageForm.get('stageName');
  }

  get stageType(): AbstractControl {
    return this.stageForm.get('stageType');
  }

  get scoring(): AbstractControl {
    return this.stageForm.get('scoring');
  }

  get skippable(): AbstractControl {
    return this.stageForm.get('skippable');
  }

  async persistStagesOrder(updatedStages: StageModel[]): Promise<void> {
    /* Force the update */
    const stagesObs = this.stageService.UpsertMultiple(updatedStages);
    await stagesObs.toPromise();
  }

  /* This method corrects the Odeyby properties to alight with the changes in the array
  *  This Method makes sure that the oderby updates as the indexes updates
  * */
  async correctOrderByInArray(array: StageModel[], fromIndex: number, toIndex: number): Promise<void> {

    const indexes: number[] = [];
    array.forEach(stage => {
      indexes.push(stage.orderby);
    });

    const element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);

    array.forEach((stage, index) => {
      stage.orderby = indexes[index];
    });

    await this.persistStagesOrder(array);
  }

  onDrop($event: CdkDragDrop<StageModel[], any>): void {
    console.log(this.stageObjects);
    moveItemInArray(this.stageObjects, $event.previousIndex, $event.currentIndex);

    console.log('prev: ' + this.stageObjects[$event.previousIndex].name + ' = ' + $event.previousIndex + '\n' +
      'current: ' + this.stageObjects[$event.currentIndex].name + ' = ' + $event.currentIndex);

    this.correctOrderByInArray(this.stageObjectscopy, $event.previousIndex, $event.currentIndex).then(r => console.log(r));
    console.log(this.stageObjectscopy);
  }
}
