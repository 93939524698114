import {ApplicationsService} from './ApplicationsService/Applications.Service';
import {CompaniesService} from './CompaniesService/Companies.Service';
import {NotificationsService} from './NotificationsService/Notifications.Service';
import {QuestionsAndAnswersService} from './QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {CandidatesPerStageService} from './StatisticsService/CandidatesPerStage.Service';
import {ReferralsService} from './StatisticsService/Referrals.Service';
import {ReferralStatsService} from './StatisticsService/ReferralStats.Service';
import {TopApplicationCountService} from './StatisticsService/TopApplicationCount.Service';
import {LoaderSpinnerService} from './LoaderSpinner/LoaderSpinner.Service';
import {UserService} from './UserServices/User.Service';
import {RoleService} from './RoleServices/Role.Service';
import {ToasterNotificationService} from './ToasterNotificationService/ToasterNotification.Service';
import {InterviewService} from './InterviewService/interview.service';

const ServicesImports = [
  ApplicationsService,
  CompaniesService,
  NotificationsService,
  QuestionsAndAnswersService,
  CandidatesPerStageService,
  ReferralsService,
  ReferralStatsService,
  TopApplicationCountService,
  LoaderSpinnerService,
  UserService,
  RoleService,
  ToasterNotificationService,
  InterviewService
];

export {ServicesImports};

