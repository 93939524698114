<header>

  <a routerLink="/landing-page" class="logo">
    <img class="company-logo" src="../../../../assets/logos/Old-Mutual.png" alt="">
  </a>

  <input type="checkbox" class="nav-toggle" id="nav-toggle">

  <div class="search-box" [class.hide-search-box]="activatedRoute.snapshot.url[0].path !== 'candidates-list'">
    <input type="search" [(ngModel)]="searchValue" placeholder="Search Applicant" aria-label="Search" class="search">
    <button class="search-button" (click)="applicantSearch(searchValue)" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
  </div>

  <nav>
    <ul>
      <li></li>
      <li *ngIf="isAdmin">
        <button type="button" (click)="addApplication()" class="button" >+ Create Application</button>
      </li>
    </ul>

  </nav>

  <label for="nav-toggle" class="nav-toggle-label">
    <span></span>
  </label>

</header>

<br>

<div class="sidenav">

  <a routerLink="/landing-page" routerLinkActive="active">
    <img src="../../../../assets/icons/buildings.svg" alt="">
    <div class="navtext">Home</div>
  </a>
<!--   <a routerLink="/company" routerLinkActive="active">-->
<!--      <img src="../../../../assets/icons/brand.svg" alt="">-->
<!--      <div class="navtext">Company</div>-->
<!--   </a>-->

  <a routerLink="/applications" routerLinkActive="active">
    <img src="../../../../assets/icons/applications.svg" alt="">
    <div class="navtext">Applications</div>
  </a>

  <a  *ngIf="isAdmin" routerLink="/users-list" routerLinkActive="active">
    <img src="../../../../assets/icons/lock.svg" alt="">
    <div class="navtext">Admin</div>
  </a>

<!--  <a routerLink="/config" routerLinkActive="active">-->
<!--    <img src="../../../../assets/icons/purple-icons/settings.svg" alt="">-->
<!--    <div class="navtext">Config</div>-->
<!--  </a>-->

  <a (click)="logout()" routerLink="/login" routerLinkActive="active">
    <img src="../../../../assets/icons/logout.svg" alt="">
    <div class="navtext">Sign Out</div>
  </a>

  <a  class="back-arrow" (click)="back()">
    <h2><i class="fa fa-arrow-left" aria-hidden="true"></i></h2>
  </a>

</div>
