import { Component, OnInit } from '@angular/core';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {LocationModel} from '../../../Core/Models/Location.Model';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {ToasterNotificationService} from '../../../Services/ToasterNotificationService/ToasterNotification.Service';
import {DocumentUploadModel} from '../../../Core/Models/DocumentUpload.Model';
import {BannerImageModel} from '../../../Core/Models/BannerImage.Model';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.scss']
})
export class AddApplicationComponent implements OnInit {

  application: ApplicationModel;
  addApplicationForm: FormGroup;
  url = '';
  bannerUrl: string;
  resultsUpload: DocumentUploadModel;
  error = false;
  shortDescriptionLength = 450;
  banner: File;
  bucketName = environment.bucketName;

  constructor(private applicationsService: ApplicationsService,
              private documentsService: DocumentsService,
              private formBuilder: FormBuilder,
              private notification: ToasterNotificationService,
              private router: Router) {
    this.application = new ApplicationModel();
    this.application.shortDescription = '';
    this.application.location = new LocationModel();
  }

  ngOnInit(): void {
    this.initiateForm();
    this.resultsUpload = new DocumentUploadModel();
    this.url = 'assets/images/hazie-banner.jpg';
  }

  submit(): void {

    this.markFormFieldsAsTouched();
    if (this.addApplicationForm.valid) {

      const formData = new FormData();
      formData.append('formFiles', this.addApplicationForm.get('bannerImage').value, this.banner.name);
      const file = formData.get('formFiles');

      if (file) {
        this.documentsService.uploadFile(this.bucketName, formData).subscribe(urlResponse => {
          const bannerImageModel = new BannerImageModel();
          bannerImageModel.applicationId = this.application.id;
          bannerImageModel.fileName = this.banner.name;
          bannerImageModel.fileType = this.banner.type;
          bannerImageModel.fileSize = this.banner.size.toString();
          bannerImageModel.url = urlResponse.toString();
          this.bannerUrl = urlResponse.toString();

          this.documentsService.UpsertBanner(bannerImageModel).subscribe(response => {
            console.log('Banner Persisted to the DB');
          });

          this.submitApplication();
        });
      }
    }
  }

  submitApplication(): void {
    if (this.addApplicationForm.valid) {
      const company = 'Hazie';
      this.application.companyId = '1f5f7eb5-734f-b345-ca11-5f75b9286328';
      this.application.isActive = true;

      if (this.application.industry !== undefined) {
        const num = +this.application.industry;
        this.application.industry = num;
      }

      if (this.application.category !== undefined) {
        const categoryNum = +this.application.category;
        this.application.category = categoryNum;
      }

      this.application.bannerImage = this.bannerUrl;
      this.application.logoImage = this.resultsUpload.id;
      this.application.shortDescription = this.application.shortDescription.substr(0, 200);
      this.application.longDescription = this.application.longDescription;
      this.application.isDeleted = false;
      this.application.dateCreated = new Date();
      this.application.deletedDate = this.application.expiryDate;
      this.application.orderBy = 0;
      this.application.location.applicationId = this.application.id;
      this.application.location.code = 0;

      if (!this.salary.value){
        this.application.salary = 0;
      } else {
        this.application.salary = +this.salary.value;
      }

      this.applicationsService.Upsert(this.application).subscribe(response => {
        this.notification.Success('Application Created');
      }, error => {
        this.notification.Failure('Something went wrong!');
      });
    }
  }

  onChange(event): void {
    if (event.target.files.length > 0) {
      this.banner = event.target.files[0];
      this.addApplicationForm.get('bannerImage').setValue(this.banner);
      console.log(this.addApplicationForm.get('bannerImage'));

      const reader = new FileReader();
      reader.onload = () => {
        /* file data to display */
        this.url = reader.result as string;
      };
      reader.readAsDataURL(this.banner);
    }
  }

  initiateForm(): void {
    this.addApplicationForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3)]],
      addressLineOne: ['', [Validators.required, Validators.minLength(3)]],
      addressLineTwo: ['', [Validators.required, Validators.minLength(3)]],
      addressLineThree: ['', [Validators.required, Validators.minLength(3)]],
      zipCode: ['', [Validators.required, Validators.minLength(3)]],
      salary: '',
      availableDate: ['', [Validators.required]],
      expiryDate: ['', [Validators.required]],
      longDescription: ['', [Validators.required, Validators.minLength(3)]],
      shortDescription: ['',
        [Validators.required, Validators.minLength(3), Validators.maxLength(450)]],
      bannerImage: [''],
      industry: ['', [Validators.required]],
      category: ['', [Validators.required]]
    });
  }

  /* -Mark form as touched- */
  markFormFieldsAsTouched(): void {
    this.title.markAsTouched();
    this.addressLineOne.markAsTouched();
    this.addressLineTwo.markAsTouched();
    this.addressLineThree.markAsTouched();
    this.zipCode.markAsTouched();
    this.salary.markAsTouched();
    this.availableDate.markAsTouched();
    this.expiryDate.markAsTouched();
    this.longDescription.markAsTouched();
    this.shortDescription.markAsTouched();
    this.industry.markAsTouched();
    this.category.markAllAsTouched();
    this.bannerImage.markAsTouched();
  }

  /* --Add-ApplicationForm Getters-- */
  get title(): AbstractControl {
    return this.addApplicationForm.get('title');
  }

  get addressLineOne(): AbstractControl {
    return this.addApplicationForm.get('addressLineOne');
  }

  get addressLineTwo(): AbstractControl {
    return this.addApplicationForm.get('addressLineTwo');
  }

  get addressLineThree(): AbstractControl {
    return this.addApplicationForm.get('addressLineThree');
  }

  get zipCode(): AbstractControl {
    return this.addApplicationForm.get('zipCode');
  }

  get salary(): AbstractControl {
    return this.addApplicationForm.get('salary');
  }

  get availableDate(): AbstractControl {
    return this.addApplicationForm.get('availableDate');
  }

  get expiryDate(): AbstractControl {
    return this.addApplicationForm.get('expiryDate');
  }

  get longDescription(): AbstractControl {
    return this.addApplicationForm.get('longDescription');
  }

  get shortDescription(): AbstractControl {
    return this.addApplicationForm.get('shortDescription');
  }

  get industry(): AbstractControl {
    return this.addApplicationForm.get('industry');
  }

  get bannerImage(): AbstractControl {
    return this.addApplicationForm.get('bannerImage');
  }

  get category(): AbstractControl {
    return this.addApplicationForm.get('bannerImage');
  }

  goToApplicationsList(): void {
    this.router.navigate(['applications']);
  }

}
