import {BaseModel} from './Base.Model';

export class CustomEmailModel extends BaseModel {
  subject: string;
  message: string;
  emailList: string[];
  emailType: string;
  jobId: string;
  sendCopy: boolean;
}
