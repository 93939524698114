import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateStageProgressModel} from '../../../../Core/Models/CandidateStageProgress.Model';
import {StagesService} from '../../../../Services/StagesService/Stages/stages.service';
import {StagescandidatejobService} from '../../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';
import {StageForSvgModel} from '../../../../Core/Models/StageForSvg.Model';
import {StageModel} from '../../../../Core/Models/Stage.Model';
import {StageProgress} from '../../../../Core/Enums/StageProgress.Model';

@Component({
  selector: 'app-stages-svg',
  templateUrl: './stages-svg.component.html',
  styleUrls: ['./stages-svg.component.scss']
})
export class StagesSvgComponent implements OnInit {

  @Input() candidateId: string;
  @Input() applicationId: string;
  @Input() stages: StageModel[];
  @Output() returnStages: EventEmitter<CandidateStageProgressModel[]> = new EventEmitter<CandidateStageProgressModel[]>();

  candidateStages: CandidateStageProgressModel[];
  filterCriteria: FilterCriteria;
  stagesForSvg: StageForSvgModel[];

  constructor(private stagesService: StagesService,
              private stagesCandidateJobService: StagescandidatejobService) { }

  ngOnInit(): void {

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys.push(this.applicationId);

    this.stagesForSvg = Array<StageForSvgModel>();
    this.candidateStages = new Array<CandidateStageProgressModel>();

    this.fetchStagesInProgress(this.candidateId, this.applicationId);

  }

  fetchStagesInProgress(candidateId: string, applicationId: string): void {
    this.stagesCandidateJobService.GetCandidateApplicationStages(candidateId, applicationId).subscribe(response => {
      this.candidateStages = response;
      this.prepareStagesForSvg();
    });
  }

  prepareStagesForSvg(): void {
    this.stages.forEach(stage => {
      const stageForSvg = new StageForSvgModel();
      stageForSvg.stageId = stage.id;
      stageForSvg.stageName = stage.name;
      stageForSvg.progress = StageProgress.Incomplete;

      this.stagesForSvg.push(stageForSvg);
    });

    this.stagesForSvg.forEach(stage => {
      this.candidateStages.forEach(x => {
        if (x.stageId === stage.stageId) {
          stage.progress = x.progress;
          stage.promotion = x.isPromoted;
          x.stageName = stage.stageName; /* Repair this.candidateStages */
        }
      });
    });

    this.returnStages.emit(this.candidateStages);
  }
}
