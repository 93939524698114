import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Guid} from 'guid-typescript';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {InterviewModel} from '../../Core/Models/Interview.Model';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {
  baseUrl = environment.baseUrl;
  interviewEndpoints = `${this.baseUrl}/QuestionsAndAnswers/interview/`;

  constructor(private https: HttpClient) { }

  GetById(id: Guid): Observable<InterviewModel> {
    return this.https.get<InterviewModel>(`${this.interviewEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<InterviewModel[]> {
    return this.https.post<InterviewModel[]>(`${this.interviewEndpoints}all`, filterCriteria);
  }

  checkIfCandidateHasAnInterview(candidateId: Guid, stageId: Guid): Observable<InterviewModel> {
    return this.https.get<InterviewModel>(`${this.interviewEndpoints}CheckForInterview?candidateid=${candidateId}&stageid=${stageId}`);
  }

  Upsert(model: InterviewModel): Observable<string> {
    return this.https.post<string>(`${this.interviewEndpoints}Upsert`, model);
  }
}
