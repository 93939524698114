import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {isArray} from 'util';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {RoleService} from '../../../Services/RoleServices/Role.Service';
import {SearchService} from '../../../Services/SearchService/Search.Service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isAdmin = false;
  searchValue: any;

  constructor(private location: Location, public authenticationService: AuthenticationService,
              private roleService: RoleService,
              private router: Router,
              public activatedRoute: ActivatedRoute,
              private searchService: SearchService) { }

  ngOnInit(): void {
    const credential =  this.authenticationService.getUserCredential();

    this.roleService.GetUserRoles(credential.username).subscribe(x => {
      if (isArray(x)) {
        x.forEach(y => {
          if (y === 'Admin') {
            this.isAdmin = true;
          }
        });
      } else {
        if (x === 'Admin') {
          this.isAdmin = true;
        }
      }
    });

  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

  applicantSearch(phrase: string): void {
    this.searchService.sendMessage(phrase);
  }


  back(): void {
    this.location.back();
  }

  addApplication(): void {
    this.router.navigate(['add-application']);
  }
}
