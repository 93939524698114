<div class="container">
  <div class="table">
    <div class="table-head" *ngIf="!showMessage && stageModel.stageType != 1 && stageModel.stageType != 3">
      <div class="table-cell">Question/Description</div>
      <div class="table-cell">Status</div>
      <div class="table-cell">Created</div>
      <div class="table-cell">Action</div>
    </div>

    <div class="table-body">
      <div *ngIf="showMessage" class="message">
        {{stageModel.name}} has no questions.
      </div>
      <div *ngIf="!showMessage"
           cdkDropList
           #list="cdkDropList"
           [cdkDropListData]="questionModelArray"
           (cdkDropListDropped)="onDrop($event)">
        <div class="table-row" *ngFor="let question of questionModelArray; let i = index" cdkDrag>
          <div class="table-cell">{{question.question}}</div>
          <div class="table-cell">{{convertIsActiveToStatus(question.isActive)}}</div>
          <div class="table-cell">
            {{question.dateCreated | date}}
            {{question.dateCreated | date: 'h:mm a'}}
          </div>
          <div class="table-cell">
            <div class="dropdown">
              <button type="button" class="button">Manage &nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></button>
              <div class="dropdown-content">
                <a (click)="editQuestion(i)" (click)="modalService.open('modal-2' + stageIndex)">Edit Question</a>
                <a class="text-danger" (click)="deleteConfirmation(i)" (click)="modalService.open('confirmation' + stageIndex)">Delete Question</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Interview Question/Stage -->
        <div class="interview" *ngIf="stageModel.stageType == 1">
          <div class="calender">
            <app-fullcalender></app-fullcalender>
          </div>
          <div class="interview-form">
            <div class="row">
              <div class="form-group">
                <label for="startTime">Start Time:</label>
                <input type="time" id="startTime" value="00:00">
              </div>
              <div class="form-group">
                <label for="endTime">End Time:</label>
                <input type="time" id="endTime" value="00:00">
              </div>
            </div>

            <div class="row">
              <div class="form-group">
                <label for="venue">
                  Venue: <br>
                  <span class="seconday-label">(Physical Address or online link)</span>
                </label>
                <input type="text" id="venue">
              </div>
            </div>

            <div class="row">
              <div class="form-group">
                <label for="notes">
                  Notes
                  <span class="seconday-label">(optional)</span>:
                </label>
                <textarea name="notes" id="notes"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="stageModel.stageType === 3" class="message">
          <form [formGroup]="odysseyConfigForm" (ngSubmit)="updateOdysseyProjectConfig()">
            <div class="row">
              <label for="projectId">Project ID:</label>&nbsp;
              <input type="text" id="projectId" formControlName="projectId">&nbsp;
              <button class="button" type="submit">Update</button>
              <br>
              <div class="error-messages" *ngIf="projectId.touched">
                <div *ngIf="projectId.invalid && projectId.dirty">* ProjectID should have 4 digits like 5669 </div>
                <div *ngIf="!projectId.value">* ProjectID can't be blank</div>
              </div>
            </div>
          </form>

          Candidate will be provided with an Odyssey Assessment link.
        </div>
      </div>


      <div class="add-question" *ngIf="stageModel.stageType != 1">
        <a (click)="addQuestion(stageModel)" (click)="modalService.open('modal-2' + stageIndex)" id="addQuestion">
          Add Question
        </a>
      </div>

      <div class="stage-comms">
        <div class="left">
          <div id="edit-stage-comms">
            <img src="../../../../assets/icons/purple-icons/chat.svg" alt="chat icon">
            <a (click)="addQuestion(stageModel)" (click)="modalService.open('stage-comms' + stageIndex)" id="addStageComms">
              Edit Stage Comms
            </a>
          </div>
          <div id="edit-email-comms">
            <img src="../../../../assets/icons/purple-icons/chat.svg" alt="chat icon">
            <a (click)="addQuestion(stageModel)" (click)="modalService.open('email-comms' + stageIndex)" id="addStageEmailComms">
              Edit Email Comms
            </a>
          </div>
        </div>
        <div class="right">
          <a id="delete-stage" (click)="modalService.open('confirmStageDelete' + stageIndex)">Delete Stage</a>
          <a id="edit-stage" (click)="triggerStageEditFromParent()">Edit Stage</a>
        </div>

      </div>
    </div>

  </div>
</div>

<!-- Delete Stage Modal Confirmation -->
<jw-modal id="confirmStageDelete{{stageIndex}}">
  <div class="header-row">
    <div class="header">
      Delete Confirmation
    </div>
    <button class="close" (click)="modalService.close('confirmStageDelete' + stageIndex)">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>
  <div class="modal-body">
    <div class="message">
      Are you sure you want to delete the {{stageModel.name}} stage?
    </div>
    <button class="button curved-button delete-btn" (click)="triggerStageDeleteFromParent()">
      Delete
    </button>
  </div>
</jw-modal>

<!-- Delete Question Modal Confirmation -->
<jw-modal id="confirmation{{stageIndex}}">
  <div class="header-row">
    <div class="header">
      Delete Confirmation
    </div>
    <button class="close" (click)="modalService.close('confirmation' + stageIndex)">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>
  <div class="modal-body">
    <div class="message">
      Are you sure you want to delete the question?
    </div>
    <button class="button curved-button delete-btn" (click)="softDeleteQuestion()">
      Delete
    </button>
  </div>
</jw-modal>

<!-- Stage Communications Modal -->
<jw-modal id="stage-comms{{stageIndex}}">
  <div class="header-row">
    <div class="header">
      {{stageModel.name}} Comms:
    </div>
    <button class="close" (click)="modalService.close('stage-comms' + stageIndex)">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="commsForm">
      <nav>
        <ul>
          <button type="button" class="button nav-button" class="{{getActive('qualified')}}" (click)="setActive('qualified')">
            <span class="green">&nbsp;&nbsp;</span>Qualified Message
          </button>
          <button type="button" class="button nav-button" class="{{getActive('unqualified')}}" (click)="setActive('unqualified')">
            <span class="red">&nbsp;&nbsp;</span>Unqualified Message
          </button>
        </ul>
      </nav>

      <small>{{applicationTitle}} - {{stageModel.name}} Stage</small><br>
<!--      <small>(150 character limit)</small><br>-->
      <textarea formControlName="qualifiedMessage" *ngIf="currentChoice === 'qualified'" name="stage-comm" id="qualified-message"></textarea><br>
      <div class="error-messages" *ngIf="qualifiedMessage.touched">
        <div *ngIf="qualifiedMessage.invalid && qualifiedMessage.dirty">* Qualified message needs to be at least 3 characters </div>
        <div *ngIf="!qualifiedMessage.value">* Qualified message can't be blank</div>
      </div>

      <textarea formControlName="unQualifiedMessage" *ngIf="currentChoice === 'unqualified'" name="stage-comm" id="unqualified-message"></textarea>
      <div class="error-messages" *ngIf="unQualifiedMessage.touched">
        <div *ngIf="unQualifiedMessage.invalid && unQualifiedMessage.dirty">* Unqualified message needs to be at least 3 characters </div>
        <div *ngIf="!unQualifiedMessage.value">* Unqualified message can't be blank</div>
      </div>
      <br>
      <button type="submit" class="button curved-button save-btn" (click)="saveStageComms(stageIndex)">
        Save
      </button>
    </form>
  </div>
</jw-modal>

<!-- Email Communications Modal -->
<jw-modal id="email-comms{{stageIndex}}">
  <div class="header-row">
    <div class="header">
      {{stageModel.name}} Email Comms:
    </div>
    <button class="close" (click)="modalService.close('email-comms' + stageIndex)">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="emailsCommsForm">
      <nav>
        <ul>
          <button type="button" class="button nav-button" class="{{getActive('qualified')}}" (click)="setActive('qualified')">
            <span class="green">&nbsp;&nbsp;</span>Qualified Message
          </button>
          <button type="button" class="button nav-button" class="{{getActive('unqualified')}}" (click)="setActive('unqualified')">
            <span class="red">&nbsp;&nbsp;</span>Unqualified Message
          </button>
        </ul>
      </nav>

      <small>{{applicationTitle}} - {{stageModel.name}} Stage</small><br>
      <textarea formControlName="emailQualifiedMessage" *ngIf="currentChoice === 'qualified'" name="email-comms" id="email-qualified-message"></textarea><br>


      <textarea formControlName="emailUnQualifiedMessage" *ngIf="currentChoice === 'unqualified'" name="email-comms" id="email-unqualified-message"></textarea>
      <div class="error-messages" *ngIf="emailUnQualifiedMessage.touched">
        <div *ngIf="emailUnQualifiedMessage.invalid && emailUnQualifiedMessage.dirty">* Unqualified email message needs to be at least 3 characters </div>
        <div *ngIf="!emailUnQualifiedMessage.value">* Unqualified email message can't be blank</div>
      </div>
      <div class="error-messages" *ngIf="emailQualifiedMessage.touched">
        <div *ngIf="emailQualifiedMessage.invalid && emailQualifiedMessage.dirty">* Qualified email message needs to be at least 3 characters </div>
        <div *ngIf="!emailQualifiedMessage.value">* Qualified email message can't be blank</div>
      </div>
      <br>
      <button type="submit" class="button curved-button save-btn" (click)="saveEmailComms(stageIndex)">
        Save
      </button>
    </form>
  </div>
</jw-modal>

<!-- Add/Edit Question Modal -->
<jw-modal id="modal-2{{stageIndex}}">
  <div class="header-row">
    <div class="header">
      <div *ngIf="addQues">Add Question</div>
      <div *ngIf="!addQues">Edit Question</div>
    </div>
    <button class="close" (click)="modalService.close('modal-2' + stageIndex)">
      <img src="../../../../assets/icons/close.svg" alt="">
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="questionForm">
      <div class="form-group" *ngIf="addQues">
        <label for="stage">Stage Name</label>
        <input type="text" id="stage" name="fname" formControlName="stageName" disabled>
      </div>
      <div class="form-group">
        <label for="question">Question</label>
        <input type="text" id="question" name="fname" formControlName="questionName">
        <div class="error-messages" *ngIf="question.touched">
          <div *ngIf="question.invalid && question.dirty">* Question needs to be at least 3 characters </div>
          <div *ngIf="!question.value">* Question can't be blank</div>
        </div>
      </div>
      <div class="form-group" *ngIf="stageModel.isScoring">
        <label for="answer">Answer</label>
        <input type="text" id="answer" name="fname" formControlName="answer">
        <div class="error-messages" *ngIf="answer.touched">
          <div *ngIf="answer.invalid && answer.dirty">* Answer needs to be at least 1 character </div>
          <div *ngIf="!answer.value">* Answer can't be blank</div>
        </div>
      </div>
      <div class="form-group" *ngIf="!addQues">
        <label for="dateCreated">Date Created</label>
        <input type="text" id="dateCreated" name="fname" formControlName="dateCreated">
      </div>
      <div class="form-group">
        <label for="questionType">Question Type</label>
        <select id="questionType" formControlName="isScoring" formControlName="questionType">
          <option>-Select-Option-</option>
          <option value="0">Free Text</option>
          <option value="1">Upload</option>
          <option value="2">Select</option>
          <option value="3">Multi-Select</option>
        </select>
        <div class="error-messages" *ngIf="questionType.touched">
          <div *ngIf="questionType.value === '-Select-Option-' && questionType.dirty">* Please choose correct Question Type</div>
          <div *ngIf="!questionType.value">* Question Type can't be blank</div>
        </div>
      </div>
      <div class="form-group" *ngIf="questionForm.controls.questionType.value === '2' ||questionForm.controls.questionType.value === 2 ">
        <label for="options1">Options NB: Add Comma separated values</label>
        <input type="text" id="options1" name="fname" formControlName="optionsSelect">
        <div class="error-messages" *ngIf="optionsSelect.touched">
          <div *ngIf="optionsSelect.invalid && optionsSelect.dirty">* Options needs to be at least 3 characters </div>
          <div *ngIf="!optionsSelect.value">* Options can't be blank</div>
        </div>
      </div>
      <div class="form-group" *ngIf="questionForm.controls.questionType.value === '3' || questionForm.controls.questionType.value === 3">
        <label for="options2">Options NB: Add Comma separated values</label>
        <input type="text" id="options2" name="fname" formControlName="optionsMultiSelect">
        <div class="error-messages" *ngIf="optionsMultiSelect.touched">
          <div *ngIf="optionsMultiSelect.invalid && optionsMultiSelect.dirty">* Options needs to be at least 3 characters </div>
          <div *ngIf="!optionsMultiSelect.value">* Options can't be blank</div>
        </div>
      </div>
      <div class="form-group">
        <label for="isActive">Status</label>
        <select id="isActive" formControlName="isActive">
          <option>-Select-Option-</option>
          <option value="true">Active</option>
          <option value="false">InActive</option>
        </select>
        <div class="error-messages" *ngIf="isActive.touched">
          <div *ngIf="isActive.value === '-Select-Option-' && isActive.dirty">* Please choose correct Status</div>
          <div *ngIf="!isActive.value">* Status can't be blank</div>
        </div>
      </div>
      <div class="buttons">
        <button type="submit" *ngIf="addQues" class="button curved-button submit" (click)="upsetAddQuestion(questionModel)">
          Submit
        </button>
        <button type="submit" *ngIf="!addQues" class="button curved-button submit" (click)="upsetEditQuestion(questionModel)">
          Submit
        </button>
        &nbsp;
        &nbsp;
        <button class="button curved-button close-button" (click)="modalService.close('modal-2' + stageIndex)">
          Close
        </button>
      </div>

    </form>
  </div>

</jw-modal>
