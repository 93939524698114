import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidateModel} from '../../../Core/Models/CandidateModelModell.Model';
import {Gender} from '../../../Core/Models/Gender.Model';
import {StagescandidatejobService} from '../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {ModalService} from '../../_modal';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomEmailModel} from '../../../Core/Models/CustomEmail.Model';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {NotificationsService} from '../../../Services/NotificationsService/Notifications.Service';
import {MailModel} from '../../../Core/Models/Mail.Model';
import {EmailTypeEnum} from '../../../Core/Enums/EmailType.Enum';
import {StagesService} from '../../../Services/StagesService/Stages/stages.service';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {CandidateStageProgressModel} from '../../../Core/Models/CandidateStageProgress.Model';
import {CandidateWithUniqueStagesModel} from '../../../Core/Models/CandidateWithUniqueStages.Model';
import {SearchService} from '../../../Services/SearchService/Search.Service';
import { Observable, of, Subscription} from 'rxjs';
import {ExportModel} from '../../../Core/Models/Export.Model';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import {QuestionsAndAnswersService} from '../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {CandidateAnswerModel} from '../../../Core/Models/CandidateAnswer.Model';
import {QuestionModel} from '../../../Core/Models/Question.Model';
import {delay, mergeMap} from 'rxjs/operators';
import * as _ from 'underscore';
import {StageWithCountModel} from '../../../Core/Models/StageWithCount.Model';
import {DocumentModel} from '../../../Core/Models/Document.Model';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';

@Component({
  selector: 'app-candidates-list',
  templateUrl: './candidates-list.component.html',
  styleUrls: ['./candidates-list.component.scss']
})
export class CandidatesListComponent implements OnInit, OnDestroy {

  currentChoice = 'All';
  applicationId: string;
  applicationTitle: string;
  candidate: CandidateModel;
  currentCandidate: CandidateModel;
  candidateList: CandidateModel[];
  candidateListFilter: CandidateModel[];
  filterCriteria: FilterCriteria;
  stages: StageModel[];
  stageNames: string[];
  stagesFilterList: CandidateStageProgressModel[];
  holdCandidateIds: Set<string>;
  uniqueCandidates: CandidateWithUniqueStagesModel[];
  candidateAnswers: CandidateAnswerModel[];

  /* stages with questions */
  stagesWithQuestions = Array<{
    stage: StageModel,
    questions: QuestionModel[]
  }>();

  /* stages with answers */
  stagesWithAnswers = Array<{
    stage: StageModel,
    answers: CandidateAnswerModel[];
  }>();

  exportData = [];
  candidatesDocuments: DocumentModel[];

  mailForm: FormGroup;
  sendCopy: boolean;
  mailSend: boolean;
  customEmailModel: CustomEmailModel;
  question: QuestionModel;
  candidateIds: string[];
  showMessage = true;
  noStageCandidates: boolean;
  noSearchResults: boolean;
  config: any;
  term: any;
  removePagination = true;
  stagesWithCount: StageWithCountModel[];

  export: ExportModel[];
  name = 'Angular 6';
  status: any[];
  formula = 'Formula 1';
  dat: any[];
  headers = [];
  simpleData;
  dropDownName = "";

  subscription: Subscription;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private stageCandidateApplication: StagescandidatejobService,
              private candidatesService: CandidatesService,
              public modalService: ModalService,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private notificationsService: NotificationsService,
              private stagesService: StagesService,
              private searchService: SearchService,
              private questionsAndAnswersService: QuestionsAndAnswersService,
              private documentsService: DocumentsService) { }

  ngOnInit(): void {
    this.applicationId  = this.activatedRoute.snapshot.paramMap.get('id');
    this.applicationTitle = this.activatedRoute.snapshot.queryParamMap.get('title');
    this.filterCriteria = new FilterCriteria();

    this.filterCriteria.CandidateKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys.push(this.applicationId);
    this.noStageCandidates = false;
    this.dat  = new Array<any>();
    this.candidateList = Array<CandidateModel>();
    this.candidateListFilter = Array<CandidateModel>();
    this.stagesFilterList = Array<CandidateStageProgressModel>();
    this.currentCandidate = new CandidateModel();
    this.holdCandidateIds = new Set<string>();
    this.uniqueCandidates = Array<CandidateWithUniqueStagesModel>();
    this.export = new Array<ExportModel>();
    this.simpleData = '';
    this.stageNames = Array<string>();
    this.stagesWithCount = Array<StageWithCountModel>();
    this.candidatesDocuments = Array<DocumentModel>();
    const stageWithCount = new StageWithCountModel();

    this.stageNames.push('All'); /* Initial choice */
    stageWithCount.stageId = '';
    stageWithCount.name = 'All';
    stageWithCount.count = 0;
    this.stagesWithCount.push(stageWithCount); /* Initial choice */

    this.fetchAllApplicationStages();
    this.fetchCandidatesGuids();

    this.initiateForm();
    this.customEmailModel = new CustomEmailModel();
    this.sendCopy = false;

    this.fetchApplicantsSearchResults();


  }

  fetchAllApplicationStages(): void {
    this.stagesService.GetByFilterCriteria(this.filterCriteria).subscribe(response => {
      this.stages = response.filter(stage => stage.isDeleted === false);

      if (response.length > 0) {
        for (let i = 0; i < response.length; i++) {
          const stageWithCount = new StageWithCountModel();
          stageWithCount.stageId = response[i].id;
          stageWithCount.name = response[i].name;
          stageWithCount.count = 0;

          this.stagesWithCount.push(stageWithCount);
        }

        for (let i = 1; i < this.stagesWithCount.length; i++) {
          this.fetchCandidatesPerStage(this.stagesWithCount[i]);
        }
      }

      this.stages.forEach(stage => {
        this.stageNames.push(stage.name);
      });
    });
  }

  fetchCandidatesPerStage(stageWithCount: StageWithCountModel): void {
    this.stageCandidateApplication.GetStageApplicants(stageWithCount.stageId).subscribe( res => {
      for (let i = 1; i < this.stagesWithCount.length; i++) {
        if (this.stagesWithCount[i].stageId === stageWithCount.stageId) {
          this.stagesWithCount[i].count = res.length;
        }
      }
    }, error => {});
  }

  fetchApplicantsSearchResults(): void {
    this.subscription = this.searchService.getMessage().subscribe(x => {
      if (x && x.text && x.text !== '') {
        this.term = x.text;
        let listOfCandidates: CandidateModel[];
        listOfCandidates = new Array<CandidateModel>();

        if ((listOfCandidates = this.candidateListFilter.filter(candidate =>
          candidate.name.toLowerCase().includes(this.term.toLowerCase()) ||
          candidate.surname.toLowerCase().includes(this.term.toLowerCase()) ||
          candidate.identityNumber === this.term)).length > 0) {
          this.noSearchResults = false;

          if (listOfCandidates.length >= 5) {
            this.removePagination = false;
          } else {
            this.removePagination = true;
          }

        } else {
          this.noSearchResults = true;
          this.removePagination = true;
        }

      } else {
        this.removePagination = false;
        this.term = undefined;
        this.noSearchResults = true;
      }
    });
  }

  sendEmail(candidate: CandidateModel): void {

    this.markFormFieldsAsTouched();

    if (this.mailForm.valid) {
      this.sendCopy = this.checkbox.value;
      const mail = new MailModel();
      const emailType = EmailTypeEnum.CustomEmail;
      const user = this.authenticationService.getUserCredential().username;

      if (this.sendCopy) {
        mail.sendCopy = this.sendCopy;
        mail.emailList = [candidate.email, user]; /*[candidate, admin]*/
      } else {
        mail.sendCopy = this.sendCopy;
        mail.emailList = [candidate.email, user];
      }

      mail.applicationId = this.applicationId.toString();
      mail.subject = this.subject.value;
      mail.message = this.message.value;
      mail.emailType = emailType;
      this.mailSend = true; /*show blue tick*/

      this.notificationsService.SendMail(mail).subscribe(response => {
        if (response === null) {
          this.mailSend = true;
        }
      });
    }
  }

  onOut(): void {
    this.modalService.close('send-email');
  }

  fetchCandidatesGuids(): void {

    this.stageCandidateApplication.GetApplicationCandidates(this.applicationId).subscribe(guids => {
      this.candidateIds = guids;
      this.fetchCandidates();
    });
  }

  fetchCandidates(): void {
    this.candidateIds.forEach(id => {
      this.filterCriteria.CandidateKeys.push(id);
    });

    this.candidatesService.GetByFilterCriteria(this.filterCriteria).subscribe( response => {
      this.candidateList = response;
      this.candidateListFilter = this.candidateList;
      this.stagesWithCount[0].count = this.candidateListFilter.length;
      // this.createExportData();

      this.config = {
        itemsPerPage: 5,
        currentPage: 1,
        totalItems: this.candidateList.length
      };

      if (this.candidateList.length !== 0) {
        this.showMessage = false;
      }

      if (this.candidateList.length >= 5) {
        this.removePagination = false;
      }
    });
  }

  patchAppliedDateToCandidate(candidateListFilter: CandidateModel[], uniqueCandidates: CandidateWithUniqueStagesModel[]): void {
    candidateListFilter.forEach(candidate => {
      uniqueCandidates.forEach(uCandidate => {
        if (candidate.id === uCandidate.candidateId) {
          candidate.applieddate = uCandidate.appliedDate;
        }
      });
    });
  }

  goToCandidateStages(candidateModel: CandidateModel): void {
    this.router.navigate(['candidate-stages', candidateModel.id],
      {
        queryParams: {
          title: this.applicationTitle,
          applicationId: this.applicationId,
          candidateName: candidateModel.name,
          candidateSurname: candidateModel.surname
        }
      });
  }

  goToCandidateProfile(candidateModel: CandidateModel): void {
    this.router.navigate(['candidate-profile', candidateModel.id],
      {
        queryParams: {title: this.applicationTitle}
      });
  }

  getCorrectProfileIcon(candidate: CandidateModel): string {
    if (candidate.gender === Gender.Male) {
      return 'assets/icons/man.svg';
    } else if (candidate.gender === Gender.Female) {
      return 'assets/icons/woman.svg';
    } else {
      return 'assets/icons/user.svg';
    }
  }

  pageChanged(event): void {
    this.config.currentPage = event;
  }

  getActive(choice: string): string {
    if (this.currentChoice === choice) {
      return 'active';
    } else {
      return 'not';
    }
  }

  setActive(choice: string): void {
    this.currentChoice = choice;
    this.filterCandidatesList(choice);
  }

  resetModal(candidate: CandidateModel): void {
    this.currentCandidate = candidate;
    this.mailSend = false;
    this.initiateForm();
  }

  initiateForm(): void {
    this.mailForm = this.formBuilder.group({
      subject: ['', [Validators.required, Validators.minLength(3)]],
      message: ['', [Validators.required, Validators.minLength(3)]],
      checkbox: false
    });
  }

  /* Mark form fields as touched */
  markFormFieldsAsTouched(): void {
    this.subject.markAsTouched();
    this.message.markAsTouched();
    this.checkbox.markAsTouched();
  }

  /* Mail-Form Getters */
  get subject(): AbstractControl {
    return this.mailForm.get('subject');
  }

  get message(): AbstractControl {
    return this.mailForm.get('message');
  }

  get checkbox(): AbstractControl {
    return this.mailForm.get('checkbox');
  }

  filterCandidatesList(choice: string): void {
    console.log(this.stagesFilterList);
    this.candidateListFilter = [];

    if (choice === 'All') { /* 'All' can be hardcoded */
      this.candidateListFilter = this.candidateList;
    } else {
      const listOfCandidatesInStage = this.stagesFilterList.filter(candidate => candidate.stageName === choice);
      this.candidateList.forEach(candidate1 => {
        listOfCandidatesInStage.forEach(candidate2 => {
          if (candidate1.id === candidate2.candidateId) {
            this.candidateListFilter.push(candidate1);
          }
        });
      });
    }

    this.config = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.candidateListFilter.length
    };

    if (this.candidateListFilter.length === 0) {
      this.noStageCandidates = true;
    } else {
      this.noStageCandidates = false;
    }

    if (this.candidateListFilter.length >= 5) {
      this.removePagination = false;
    }
  }

  /* This function fetches info(stages) from the child-component */
  getCandidateStages(candidateStages: CandidateStageProgressModel[]): void {

    candidateStages.forEach(candidate => {
      if (this.holdCandidateIds.has(candidate.candidateId)) { /* Avoid duplicates */
        this.uniqueCandidates.forEach(uCandidate => {
          if (candidate.candidateId === uCandidate.candidateId) {
            if (uCandidate.stages.has(candidate.stageId)) {
              console.log('stage already exists, so pass');
            } else {
              this.stagesFilterList.push(candidate);
              uCandidate.stages.add(candidate.stageId);
              uCandidate.appliedDate = candidateStages[0].appliedDate; /* Patch applied date */
            }
          }
        });

      } else {

        const uniqueCandidate = new CandidateWithUniqueStagesModel();
        uniqueCandidate.candidateId = candidate.candidateId;
        uniqueCandidate.stages = new Set<string>();
        uniqueCandidate.stages.add(candidate.stageId);
        uniqueCandidate.appliedDate = candidateStages[0].appliedDate; /* Patch applied date */
        this.uniqueCandidates.push(uniqueCandidate);
        this.holdCandidateIds.add(candidate.candidateId);
        this.stagesFilterList.push(candidate);
      }
    });

    this.patchAppliedDateToCandidate(this.candidateListFilter, this.uniqueCandidates);

  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async triggerExport(stage: any): Promise<void> {
    await this.fetchAllQuestionsFromApplication();
    this.fetchDocuments();
    console.log(this.stagesWithQuestions);
    console.log();
    if (stage === 'All') {
      console.log('All');
      await this.exportAll();
      console.log(this.stagesWithAnswers);
    } else {
      console.log(stage.name);
    }
  }

  /* Exports
  * */

  async exportAll(): Promise<void> {

    if (this.stagesWithQuestions.length > 0) {
      for (const stageObject of this.stagesWithQuestions) {
        const stageWithAnswers = {
          stage: stageObject.stage,
          answers: await this.fetchAnswersByStage(stageObject.stage.id)
        };

        console.log(stageWithAnswers);
        this.stagesWithAnswers.push(stageWithAnswers);
      }

      console.log(this.stagesWithAnswers);
      this.createExportFile();
    }
  }

  exportByStageName(): void {

  }

  /* Export Helper functions
  * */

  returnDocumentIfAvailable(candidate: CandidateModel, question: QuestionModel): any {
    let link = '';
    this.candidatesDocuments.forEach(doc => {
      if (doc.candidateId === candidate.id && doc.question === question.question) {
        link = doc.url;
      }
    });

    return link;
  }

  returnCorrectAnswer(candidate: CandidateModel, question: QuestionModel): any {
    let finalAnswer = '';

    this.stagesWithAnswers.forEach(stage => {
      if (stage.stage.id === question.stageId) {
        stage.answers.forEach(answer => {
          if (answer.candidateId === candidate.id && answer.questionId === question.id) {
            finalAnswer = answer.answer;
          }
        });
      }
    });

    /* Cater for documents links */
    if (finalAnswer === '') {
      finalAnswer = this.returnDocumentIfAvailable(candidate, question);
    }

    return finalAnswer;
  }

  returnCorrectAnswers(candidate: CandidateModel, stage: StageModel, questions: QuestionModel[]): any[] {
    const answers = [];

    questions.forEach(question => {
      if (!question.isDeleted) {
        answers.push(this.returnCorrectAnswer(candidate, question));
      }
    });

    return answers;
  }

  matchCandidateToAnswers(candidate: CandidateModel): any {
    let row = [candidate.name, candidate.surname, candidate.identityNumber, candidate.email];
    // const filteredAnswers = answers.filter(answer => answer.candidateId === candidate.id);

    this.stagesWithQuestions.forEach(stage => {
      console.log(this.returnCorrectAnswers(candidate, stage.stage, stage.questions));
      row = row.concat(this.returnCorrectAnswers(candidate, stage.stage, stage.questions));
    });

    return row;
  }

  createExportFile(): void {
    const headersList  = ['Name', 'Surname', 'Identity Number', 'Email'];
    this.exportData = [];

    this.stagesWithQuestions.forEach(stage => {
      stage.questions.forEach(question => {
        if (!question.isDeleted) {
          headersList.push(question.question);
        }
      });
    });

    const options = {
      title: 'User Details',
      fieldSeparator: ',',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: false,
      headers: headersList
    };

    const formula = this.applicationTitle + '_' + 'All' + '_' + 'Hazie_Export';

    console.log(this.candidateList);
    console.log(this.stagesWithQuestions);
    console.log(this.stagesWithAnswers);
    this.candidateList.forEach(candidate => {
      this.exportData.push(this.matchCandidateToAnswers(candidate));
    });

    /* This line trickers the download */
    new Angular2Csv(this.exportData, formula, options);

  }

  fetchAllCandidateAnswersByApplication(applicationID: string): void {

  }

  async fetchAllQuestionsFromApplication(): Promise<void> {

    for (const stageObj of this.stages) {
      const stageWithQuestions = {
        stage : stageObj,
        questions : await this.fetchQuestionsByStage(stageObj)
      };

      console.log(stageWithQuestions);
      this.stagesWithQuestions.push(stageWithQuestions);
    }

    console.log(this.stagesWithQuestions);
  }

  async fetchQuestionsByStage(stage: StageModel): Promise<QuestionModel[]> {
    const filterCriteria = new FilterCriteria();
    filterCriteria.StageKeys = [stage.id];
    let questions = Array<QuestionModel>();

    const questionsObservs = this.questionsAndAnswersService.GetByFilterCriteria(filterCriteria);
    questions = await questionsObservs.toPromise();

    return questions;
  }

  async fetchAnswersByStage(stageId: string): Promise<CandidateAnswerModel[]> {
    let answers = [];

    const answersObservebles = this.questionsAndAnswersService.GetAllStageAnswers(stageId);
    answers = await answersObservebles.toPromise();

    return answers;
  }

  fetchDocuments(): void {
    this.documentsService.GetAllDocuments().subscribe(response => {
      this.candidatesDocuments = response;
      });
  }

  createExportData(){
    const csvRows = [];

    if (this.candidateList.length > 0) {
    this.candidateList.forEach(candidate => {
      this.stages.forEach(stage => {
            this.questionsAndAnswersService.GetAllCandidateStageAnswers(candidate.id, stage.id)
              .pipe(mergeMap(answers => answers.map(answer => this.questionsAndAnswersService.GetById(answer.questionId)
                .subscribe( data => {
                  const exportTemp = new ExportModel();
                  exportTemp.name = candidate.name;
                  exportTemp.surname = candidate.surname;
                  exportTemp.identityNumber = candidate.identityNumber;
                  exportTemp.stage = stage.name;
                  exportTemp.question = data.question;
                  exportTemp.answer = answer.answer;

                  console.log(exportTemp);
                  const index = this.export.findIndex(x =>  x.name === exportTemp.name && x.surname === exportTemp.surname );
                  // here you can check specific property for an object whether it exist in your array or not
                  if (index === -1) {
                    this.export.push(exportTemp);

                  } else {
                    console.log('candidate already exists');
                   // exportTemp.name = '*';
                    //exportTemp.surname = '*';
                    //exportTemp.identityNumber = '*';
                    this.export.push(exportTemp);
                  }

                })) )).subscribe( x => {

            });

          });
      });
    }


  }

  exportByStage(){

    if (this.export.length > 0){
      console.log(this.dropDownName);




      const options = {
        title: 'User Details',
        fieldSeparator: ',',
        quoteStrings: '',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: false,
        headers: ['Name', 'Surname', 'Identity Number',  'Stage', 'Question', 'Answer']
      };

      if (this.dropDownName === 'All'){
          this.formula = this.applicationTitle + '_' + this.dropDownName + '_' + 'hazie_export';
         // this.export = _.sortBy(this.export, 'name');
        new Angular2Csv(this.export, this.formula, options);

      }else{
        this.formula = this.applicationTitle + '_' + this.dropDownName + '_' + 'hazie_export';
        let filterStages = this.export.filter(x => x.stage === this.dropDownName);
       // filterStages =  _.sortBy(filterStages, 'name');
        new Angular2Csv(filterStages, this.formula, options);
      }




    }



  }

  getCandidates(): Observable<CandidateModel[]>{
    return of(this.candidateList).pipe(delay(500));
  }

  fetchCandidateStageAnswers(candidateId, stageId){

 this.questionsAndAnswersService.GetAllCandidateStageAnswers(candidateId, stageId).subscribe(resultAnswers => {
      this.candidateAnswers = resultAnswers;
      this.getQuestionsForEachAnswer((this.candidateAnswers));
    });
   // const resultAllAnswers =  await observableAllAnswers.toPromise();


  }

  getQuestionsForEachAnswer(candidateAnswers: CandidateAnswerModel[]){
    if (candidateAnswers.length > 0){
      for (let i = 0; i < candidateAnswers.length; i++)
      {
       this.questionsAndAnswersService.GetById( candidateAnswers[i].questionId).subscribe(result => {
         candidateAnswers[i].question = result.question;
         if (result){
           this.dat.push(result.question);
           this.dat.push(candidateAnswers[i].answer);
           console.log(this.dat, 'Data');
         }
       });
      }
      this.candidateAnswers = candidateAnswers;

    }
  }


}
