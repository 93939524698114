import {Component, Input, OnInit} from '@angular/core';
import {StagescandidatejobService} from '../../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';
import {StageWithCountModel} from '../../../../Core/Models/StageWithCount.Model';
import {StageProgress} from '../../../../Core/Enums/StageProgress.Model';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  /* Inputs */
  @Input() stage: StageWithCountModel;

  qualifiedCandidates = 0;
  unqualifiedCandidates = 0;
  candidatesInProgress = 0;

  /* Doughnut Chart Settings */
  public doughnutChartLabels: Label[] = ['Qualified Candidates', 'Unqualified Candidates', 'Candidates in Progress'];
  doughnutChartDataChartColors: any[] = [];
  public doughnutChartData: SingleDataSet[] = [
    [this.qualifiedCandidates, this.unqualifiedCandidates, this.candidatesInProgress]
  ];
  public doughnutChartType: ChartType = 'doughnut';
  donutChartOptions: ChartOptions = {
    responsive: true,

    legend: {position: 'bottom',
      align: 'center',
      labels: {
        boxWidth : 10
      }},
  };

  constructor(private stageCandidateApplication: StagescandidatejobService) { }

  ngOnInit(): void {

    this.doughnutChartDataChartColors = [
      {
        backgroundColor: ['#47E1F4', '#8B40A5', '#2793CD']
      }];

    this.fetchStageCandidates();
  }

  fetchStageCandidates(): void {
    this.stageCandidateApplication.GetStageApplicants(this.stage.stageId).subscribe( res => {
      res.forEach( candidate => {
        if (candidate.progress === StageProgress.Complete && !candidate.isPromoted) {
          this.unqualifiedCandidates++;
        } else if (candidate.progress === StageProgress.InProgress) {
          this.candidatesInProgress++;
        } else if (candidate.progress === StageProgress.Complete && candidate.isPromoted) {
          this.qualifiedCandidates++;
        }
      });

      this.doughnutChartData = [
        [this.qualifiedCandidates, this.unqualifiedCandidates, this.candidatesInProgress]
      ];
    }, error => {
      console.log(error);
    });
  }

  /* Events */
  public chartClicked( e: any ): void {
    console.log(e);
  }

  public chartHovered( e: any ): void {
    console.log(e);
  }

}
