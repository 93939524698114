import {Component, OnInit} from '@angular/core';
import {CandidateModel} from '../../../Core/Models/CandidateModelModell.Model';
import {Gender} from '../../../Core/Models/Gender.Model';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {ActivatedRoute, Router} from '@angular/router';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {StagescandidatejobService} from '../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';

@Component({
  selector: 'app-candidates-profile',
  templateUrl: './candidates-profile.component.html',
  styleUrls: ['./candidates-profile.component.scss']
})
export class CandidatesProfileComponent {

  candidate: CandidateModel;
  applications: ApplicationModel[];
  app: ApplicationModel;
  showMessage: boolean;
  candidateId: string;
  filterCriteria: FilterCriteria;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private candidatesService: CandidatesService,
              private stagesCandidateJobService: StagescandidatejobService,
              private applicationsService: ApplicationsService) { }

  ngOnInit(): void {

    this.candidateId = this.activatedRoute.snapshot.paramMap.get('id');


    this.candidate = new CandidateModel();
    this.showMessage = false;

    this.fetchCandidate(this.candidateId);

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.applications = Array<ApplicationModel>();

    this.fetchApplicationIds(this.candidateId);
  }

  fetchCandidate(candidateId: string): void {
    this.candidatesService.GetById(candidateId).subscribe(candidate => {
      this.candidate = candidate;
    });
  }

  fetchApplicationIds(candidateId: string): void {
    this.stagesCandidateJobService.GetCandidateApplications(candidateId).subscribe(ids => {

      ids.forEach(id => {
        this.filterCriteria.ApplicationsKeys.push(id);
      });

      this.fetchApplications(this.filterCriteria);
    });
  }

  fetchApplications(filterCriteria: FilterCriteria): void {
    this.applicationsService.GetByFilterCriteria(filterCriteria).subscribe(applications => {
      this.applications = applications;

      if (this.applications.length === 0) {
        this.showMessage = true;
      }
    });
  }

  goToCandidateStages(candidateModel: CandidateModel, applicationModel: ApplicationModel): void {
    this.router.navigate(['candidate-stages', candidateModel.id],
      {
        queryParams: {
          title: applicationModel.title,
          applicationId: applicationModel.id,
          candidateName: candidateModel.name,
          candidateSurname: candidateModel.surname
        }
      });
  }

  getCorrectProfileIcon(candidate: CandidateModel): string {
    if (candidate.gender === Gender.Male) {
      return 'assets/icons/man.svg';
    } else if (candidate.gender === Gender.Female) {
      return 'assets/icons/woman.svg';
    } else {
      return 'assets/icons/user-2.svg';
    }
  }
}
