import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationsListComponent } from './applications/applications-list/applications-list.component';
import {NavbarComponent} from './common/navbar/navbar.component';
import { ApplicationsStagesComponent } from './applications/applications-stages/applications-stages.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AdminComponent } from './admin/admin.component';
import {RouterModule} from '@angular/router';
import { ApplicationCardComponent } from './applications/add-application/application-card/application-card.component';
import { ViewApplicationComponent } from './applications/view-application/view-application.component';
import { ApplicationStatisticsComponent } from './applications/view-application/application-statistics/application-statistics.component';
import {ChartsModule} from 'ng2-charts';
import {LoadStageQuestionsComponent} from './applications/applications-stages/load-stage-questions/load-stage-questions.component';
import {CandidatesListComponent} from './candidates/candidates-list/candidates-list.component';
import {CandidatesProfileComponent} from './candidates/candidates-profile/candidates-profile.component';
import {CandidatesStagesComponent} from './candidates/candidates-stages/candidates-stages.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadCandidateAnswersComponent} from './candidates/candidates-stages/load-candidate-answers/load-candidate-answers.component';
import {LoginComponent} from './accounts/login/login.component';
import {RegisterComponent} from './accounts/register/register.component';
import {ForgetPasswordComponent} from './accounts/forget-password/forget-password.component';
import {AddApplicationComponent} from './applications/add-application/add-application.component';
import {ModalModule} from './_modal';
import { StagesSvgComponent } from './candidates/candidates-list/stages-svg/stages-svg.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import { UserEditComponent } from './config/users/user-edit/user-edit.component';
import { CompanyComponent } from './companies/company/company.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import { RegisterSuccessComponent } from './accounts/register-success/register-success.component';
import { VerifyUserComponent } from './accounts/verify-user/verify-user.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {EditApplicationComponent} from './applications/edit-application/edit-application.component';
import {UserGridComponent} from './config/users/user-grid/user-grid.component';
import {RoleGridComponent} from './config/roles/role-grid/role-grid.component';
import { ErrorComponent } from './accounts/error/error.component';
import { ChangePasswordComponent } from './accounts/change-password/change-password.component';
import { FullcalenderComponent } from './common/fullcalender/fullcalender.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import { PieChartComponent } from './applications/view-application/pie-chart/pie-chart.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

/* Please Add custom components here */
export const components = [
  ApplicationsListComponent,
  NavbarComponent,
  ApplicationsStagesComponent,
  LandingPageComponent,
  AdminComponent,
  LoadStageQuestionsComponent,
  CandidatesListComponent,
  CandidatesProfileComponent,
  CandidatesStagesComponent,
  LoadCandidateAnswersComponent,
  LoginComponent,
  RegisterComponent,
  ForgetPasswordComponent,
  AddApplicationComponent,
  ApplicationCardComponent,
  ViewApplicationComponent,
  ApplicationStatisticsComponent,
  StagesSvgComponent,
  RoleGridComponent,
  UserEditComponent,
  UserGridComponent,
  VerifyUserComponent,
  RegisterSuccessComponent,
  EditApplicationComponent,
  FullcalenderComponent,
  CompanyComponent,
  EditApplicationComponent,
  ErrorComponent,
  ChangePasswordComponent,
  PieChartComponent
];

/* Please Don't Add custom components here */
@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    RouterModule,
    ChartsModule,
    ReactiveFormsModule,
    ModalModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    FormsModule,
    EditorModule,
    NgxSpinnerModule,
    FullCalendarModule,
    DragDropModule
  ],
  exports: [components]
})
export class ComponentsModule {
  constructor() {
  }
}
