import {Component, OnInit} from '@angular/core';
import {RoleGridItemModel} from './role-grid-item.model';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationsService} from '../../../../Services/ApplicationsService/Applications.Service';
import {FilterCriteria} from "../../../../Core/Models/FilterCriteria";

@Component({
  selector: 'app-role-grid',
  templateUrl: './role-grid.component.html',
  styleUrls: ['./role-grid.component.scss'],
})
export class RoleGridComponent implements OnInit {
  items: RoleGridItemModel[];

  constructor(private router: Router, private modalService: NgbModal, private applicationsService: ApplicationsService) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    const filter = new FilterCriteria();
    filter.CompanyKeys = ['3b8633ea-0a29-4f0f-adeb-1047660c5ecf']; // Old Mutual Key
    this.items = [];
    this.applicationsService.GetByFilterCriteria(filter).subscribe(data => {
     data.forEach(x => {
       const role = new RoleGridItemModel();
       role.id = x.id;
       role.name = x.title;
       this.items.push(role);
     });
    });
  }

  delete(item: RoleGridItemModel) {
  }
}
