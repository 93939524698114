import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput} from '@fullcalendar/angular';
import {InterviewModel} from '../../../Core/Models/Interview.Model';

@Component({
  selector: 'app-fullcalender',
  templateUrl: './fullcalender.component.html',
  styleUrls: ['./fullcalender.component.scss']
})
export class FullcalenderComponent implements OnInit {

  @Input() interviewModel: InterviewModel;
  @Input() candidateName: string;
  @Input() candidateSurname: string;
  @Output() returnInterviewModel: EventEmitter<InterviewModel> = new EventEmitter<InterviewModel>();
  calendarVisible = true;
  calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];
  previousEvents: EventInput[];
  eventGuid = 0;

  constructor() { }

  ngOnInit(): void {

    this.checkIfCandidateHasAnInterview();

    this.calendarOptions = {
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next'
      },
      initialView: 'dayGridMonth',
      // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventsSet: this.handleEvents.bind(this),
      initialEvents: this.previousEvents
      /* you can update a remote database when these fire:
      eventAdd:
      eventChange:
      eventRemove:
      */
    };
  }

  checkIfCandidateHasAnInterview(): void {
    if (this.interviewModel.name && this.interviewModel.startTime && this.interviewModel.endTime) {
      this.previousEvents = [
        {
          id: String(this.eventGuid++),
          title: this.candidateName + ' ' + this.candidateSurname + "\'s Interview",
          start: this.handleDate(this.interviewModel.date),
        }
      ];
    } else {
      this.previousEvents = [];
    }
  }

  handleDate(date: string): string {
    if (date) {
      return date;
    } else {
      return new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
    }
  }

  handleCalendarToggle(): void {
    this.calendarVisible = !this.calendarVisible;
  }

  createEventId(): string {
    return String(this.eventGuid++);
  }

  handleDateSelect(selectInfo: DateSelectArg, clickInfo: EventClickArg): void {
    console.log(selectInfo.startStr);
    const title = this.candidateName + ' ' + this.candidateSurname + "\'s Interview";

    const calendarApi = selectInfo.view.calendar;

    if (title) {
      calendarApi.removeAllEvents();
      calendarApi.addEvent({
        id: this.createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
      this.interviewModel.date = selectInfo.startStr;
      this.interviewModel.name = this.candidateName + ' ' + this.candidateSurname + "\'s Interview";
      this.returnInterviewModel.emit(this.interviewModel);
    }
  }

  // useless for now
  handleEventClick(clickInfo: EventClickArg): void {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]): void {
    this.currentEvents = events;
  }

}
