import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ServicesModule} from './Services/services.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpErrorHandlerService} from './Services/ErrorHandlingService/HttpErrorHandler.Service';
import {ComponentsModule} from './Components';
import {ChartsModule} from 'ng2-charts';
import {ModalModule} from './Components/_modal';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoaderSpinnerService} from './Services/LoaderSpinner/LoaderSpinner.Service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {RefreshTokenInterceptor} from './Services/AuthenticationService/refresh-token.interceptor';
import {CookieService} from 'ngx-cookie-service';
import {TokenInterceptor} from './Services/AuthenticationService/token.interceptor';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ServicesModule,
    HttpClientModule,
    ComponentsModule,
    ChartsModule,
    ModalModule,
    NgxSpinnerModule,
    FullCalendarModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderSpinnerService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerService, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
