import {CandidateModel} from './CandidateModelModell.Model';
import {StageForExportModel} from './StageForExport.Model';
import {Gender} from './Gender.Model';

export class ExportModel {
  name: string;
  surname: string;
  identityNumber: string;
  stage: string;
  question: string;
  answer: string;
}
