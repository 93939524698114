<app-navbar></app-navbar>

<!--<div class="main main-container">
  <div class="left">
    <div class="text">Left</div>
    <div class="box">Box Left</div>
  </div>
  <div class="right">
    <div class="text">Right</div>
    <div class="box">Box Right</div>
  </div>
</div>-->
<div class="main-container">
<!--  <div class="company-title">
    <div class="create-company">Create Company</div>
    <div class="preview">Preview</div>

  </div>-->
  <div class="parent">

    <div class="item">
      <div class="text">Create Company</div>
      <div class="box">
        <div class="form">

          <form [formGroup]="addCompanyForm" (ngSubmit)="onSubmit()">
            <div class="company-name">
              <label>Company Name:</label>
              <input type="text" id="companyname" formControlName="companyname" [(ngModel)]="company.name" placeholder="">
            </div>
            <div class="address">
              <label>Address:</label>
              <input type="text" id="lineone" formControlName="lineone" [(ngModel)]="company.address.lineone" placeholder="">
              <input type="text" id="linetwo" formControlName="linetwo" [(ngModel)]="company.address.linetwo" placeholder="">
              <input type="text" id="linethree" formControlName="linethree" [(ngModel)]="company.address.linethree" placeholder="">
              <div class="zip-code">
                <label>Zip code:</label>
                <input type="text" class="zip-code" id="linefour" [(ngModel)]="company.address.linefour" formControlName="linefour" placeholder="">
              </div>
            </div>
            <div class="logo">
              <label>Upload Logo:</label>
              <input type="file" id="logo" class="image" name="logo" (change)="onChange($event) "formControlName="logo" placeholder="">
            </div>
            <div class="industry">
              <label>Industry:</label>
              <select>
                <option value="1">Information Technology</option>
                <option value="2">Engineering</option>
                <option value="3">Administration</option>
                <option value="4">Manufacturing</option>
                <option value="5">HealthCare</option>
                <option value="6">Finance</option>
                <option value="7">Retail</option>
                <option value="8">Accounting</option>
                <option value="9">Construction</option>
                <option value="9">Media</option>
              </select>

            </div>
            <div class="primary-color">
              <label>Select Company Primary Colour:</label>
              <input type="color" id="primaryColor" formControlName="primaryColor" [(ngModel)]="company.primaryColor" placeholder="">
            </div>
            <div class="secondary-color">
              <label>Select Company Secondary Colour:</label>
              <input type="color" id="secondaryColor" formControlName="secondaryColor" [(ngModel)]="company.secondaryColor" placeholder="">
            </div>
            <hr/>
            <div class="buttons">
              <button type="submit" class="button curved-button submit">Submit</button>
              &nbsp;
              &nbsp;
              <button type="submit" class="button curved-button close-button" >Cancel</button>
            </div>

          </form>
        </div>
      </div>

    </div>

    <div class="item last">
      <div class="text">Preview</div>
      <div class="box">
        <div class="form-display">
          <div class="company-title-preview">
            <label>Company Name:</label>
            <div>
              {{company.name}}
            </div>

          </div>
          <div class="address-preview">
            <label>Address:</label>
            <div>
              {{company.address.lineone}}<br/>
              {{company.address.linetwo}} <br/>
              {{company.address.linethree}}<br/>
              {{company.address.linefour}}
            </div>
          </div>
          <div class="logo-preview">
            <label>Logo:</label>
            <img  [src]="url"  />
          </div>
          <label>Select {{this.company.name}} Primary Color</label>
          <div class="primary-color-preview" style="background-color: {{company.primaryColor}}">
          </div>
          <label>Select {{this.company.name}} Secondary Color</label>
          <div class="secondary-color-preview" style="background-color:{{company.secondaryColor}}">
          </div>

        </div>
      </div>


    </div>

  </div>

</div>
