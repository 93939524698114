<div class="doughnut">
  <h2>{{stage.name}}</h2>
  <canvas baseChart
          height="250"
          [options]="donutChartOptions"
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [colors]="doughnutChartDataChartColors"
          [chartType]="doughnutChartType"
          (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)">
  </canvas>
</div>

