import { Component, OnInit } from '@angular/core';
import {CompaniesService} from '../../../Services/CompaniesService/Companies.Service';
import {CompanyModel} from '../../../Core/Models/Company.Model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {LocationCompanyModel} from '../../../Core/Models/LocationCompany.Model';
import {Guid} from 'guid-typescript';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  company: CompanyModel;
  addCompanyForm: FormGroup;
  imageSrc: string;
  url = '';


  constructor(private companyService: CompaniesService,
              private documentsService: DocumentsService,
              private formBuilder: FormBuilder) {
    this.company = new CompanyModel();
    this.company.address = new LocationCompanyModel();
  }

  ngOnInit(): void {
    this.addCompanyForm = this.formBuilder.group({
      companyname: new FormControl(''),
      lineone: [],
      linetwo: [],
      linethree: [],
      linefour: [],
      logo: [],
      primaryColor: [],
      secondaryColor: []

    });

  }

  async onSubmit() {


    this.company.isActive = true;
    this.company.contactDetails = '';
    this.company.address.companyId = this.company.id;

    this.company.name = this.addCompanyForm.get('companyname').value;
    this.company.address.lineone = this.addCompanyForm.get('lineone').value;
    this.company.address.linetwo = this.addCompanyForm.get('linetwo').value;
    this.company.address.linethree = this.addCompanyForm.get('linethree').value;
    this.company.address.linefour = this.addCompanyForm.get('linefour').value;


    const fileUpload = new FormData();
    fileUpload.append('file', this.addCompanyForm.get('logo').value);
    const file = fileUpload.get('file');

    if (file !== '') {

      fileUpload.append('company', this.company.name);

      console.log(fileUpload);
      // const observableUpload = this.documentsService.uploadCompany(fileUpload, this.company.name);
      // var resultsUpload = await observableUpload.toPromise();




      // resultsUpload.sourceId = this.company.id;
      // resultsUpload.uploadEntityName = this.company.name;


      // const oberservableUploadUpsert = this.documentsService.Upsert(resultsUpload);
      // const resultUploadUpsert = await oberservableUploadUpsert.toPromise();
    }



    this.company.primaryColor = this.addCompanyForm.get('primaryColor').value;
    this.company.secondaryColor = this.addCompanyForm.get('secondaryColor').value;

    // this.company.logo = resultsUpload.id;

    const observableCompany = this.companyService.Upsert(this.company);
    const resultCompany = await observableCompany.toPromise();



  }


/*  readURL(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.addCompanyForm.patchValue({
      avatar: file
    });
    this.addCompanyForm.get('logo').updateValueAndValidity();
    this.addCompanyForm.get('logo').setValue(file);


    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageSrc = reader.result as string;
    }
    reader.readAsDataURL(file);
  }*/

  onChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addCompanyForm.get('logo').setValue(file);

      const reader = new FileReader();
      reader.onload = () => {
        this.url = reader.result as string;
      }
      reader.readAsDataURL(file);
    }
  }
}
