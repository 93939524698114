<app-navbar></app-navbar>
<!--  New  -->
<div class="main-container">
  <div class="flex-container">
    <div class="flex-left">
      <div class="header-style">Edit Application</div>
    </div>
    <div class="flex-right">
      <div class="header-style">Preview</div>
    </div>
  </div>
  <div class="flex-container">
    <div class="flex-left">
      <form [formGroup]="editApplicationForm" (ngSubmit)="submit()">

        <div class="form-group">
          <label for="title">Title:</label>
          <input type="text" id="title" name="fname" formControlName="title"  [(ngModel)]="application.title">
          <div class="error-messages" *ngIf="title.touched">
            <div *ngIf="title.invalid && title.dirty">* Title needs to be at least 3 characters</div>
            <div *ngIf="!title.value">* Title can't be blank</div>
          </div>
        </div>

        <div class="form-group">
          <label for="addressLineOne">Address:</label>
          <input type="text" id="addressLineOne" name="fname" formControlName="addressLineOne" [(ngModel)]="application.location.lineone">
          <div class="error-messages" *ngIf="addressLineOne.touched">
            <div *ngIf="addressLineOne.invalid && addressLineOne.dirty">* Address needs to be at least 3 characters</div>
            <div *ngIf="!addressLineOne.value">* Address can't be blank</div>
          </div>

          <input type="text" id="addressLineTwo" name="fname" formControlName="addressLineTwo" [(ngModel)]="application.location.linetwo">
          <div class="error-messages" *ngIf="addressLineTwo.touched">
            <div *ngIf="addressLineTwo.invalid && addressLineTwo.dirty">* Address needs to be at least 3 characters</div>
            <div *ngIf="!addressLineTwo.value">* Address can't be blank</div>
          </div>

          <input type="text" id="addressLineThree" name="fname" formControlName="addressLineThree" [(ngModel)]="application.location.linethree">
          <div class="error-messages" *ngIf="addressLineThree.touched">
            <div *ngIf="addressLineThree.invalid && addressLineThree.dirty">* Address needs to be at least 3 characters</div>
            <div *ngIf="!addressLineThree.value">* Address can't be blank</div>
          </div>

          <div>
            <div class="zip-code-group">
              <label for="zipCode" class="zip">Zip Code:</label>&nbsp;&nbsp;
              <input type="text" id="zipCode" name="fname" formControlName="zipCode" [(ngModel)]="application.location.linefour">
            </div>
            <div class="error-messages" *ngIf="zipCode.touched">
              <div *ngIf="zipCode.invalid && zipCode.dirty">* Zip Code needs to be at least 3 characters</div>
              <div *ngIf="!zipCode.value">* Zip Code can't be blank</div>
            </div>
          </div>
        </div>



        <div class="form-group">
          <label for="salary">Salary: (Optional)</label>
          <input type="number" id="salary" name="fname" formControlName="salary" [(ngModel)]="application.salary">
        </div>

        <div class="form-group">
          <label for="availableDate">Available Date:</label>
          <input type="date" id="availableDate" name="fname" formControlName="availableDate"
                 [ngModel]="application.availableDate | date:'yyyy-MM-dd'"
                 (ngModelChange)=" application.availableDate = $event">
          <div class="error-messages" *ngIf="availableDate.touched">
            <div *ngIf="availableDate.invalid && availableDate.dirty">* Date not valid</div>
            <div *ngIf="!availableDate.value">* Date can't be blank</div>
          </div>
        </div>

        <div class="form-group">
          <label for="expiryDate">Expiry Date:</label>
          <input type="date" id="expiryDate" name="fname" formControlName="expiryDate"
                 [ngModel]="application.expiryDate | date:'yyyy-MM-dd'"
                 (ngModelChange)=" application.expiryDate = $event">
          <div class="error-messages" *ngIf="expiryDate.touched">
            <div *ngIf="expiryDate.invalid && expiryDate.dirty">* Date not valid</div>
            <div *ngIf="!expiryDate.value">* Address can't be blank</div>
          </div>
        </div>

        <div class="form-group">
          <label for="industry">Industry:</label>
          <select name="industry" id="industry" formControlName="industry" [(ngModel)]="application.industry">
            <option>-Select-Option-</option>
            <option value="1">Information Technology</option>
            <option value="2">Engineering</option>
            <option value="3">Administration</option>
            <option value="4">Manufacturing</option>
            <option value="5">HealthCare</option>
            <option value="6">Finance</option>
            <option value="7">Retail</option>
            <option value="8">Accounting</option>
            <option value="9">Construction</option>
            <option value="9">Media</option>
          </select>
          <div class="error-messages" *ngIf="industry.touched">
            <div *ngIf="industry.value === '-Select-Option-' && industry.dirty">* Please choose correct Industry</div>
            <div *ngIf="!industry.value && industry.invalid">* Industry can't be blank</div>
          </div>
        </div>


        <div class="form-group">
          <label for="category">Category:</label>
          <select name="category" id="category" formControlName="category" [(ngModel)]="application.category">
            <option>-Select-Option-</option>
            <option value="1">Bursaries</option>
            <option value="2">Graduates</option>
            <option value="3">Learnerships</option>
            <option value="4">Internships</option>
            <option value="5">Apprenticeships</option>
          </select>
          <div class="error-messages" *ngIf="category.touched">
            <div *ngIf="category.value === '-Select-Option-' && category.dirty">* Please choose correct Category</div>
            <div *ngIf="!category.value && category.invalid">* Category can't be blank</div>
          </div>
        </div>

        <div class="form-group">
          <label for="shortDescription">Short Description:
            <small>({{shortDescriptionLength - application.shortDescription.length}} character limit)</small>
          </label>
          <input
            maxlength="450"
            type="text"
            id="shortDescription"
            name="fname"
            formControlName="shortDescription"
            [(ngModel)]="application.shortDescription">
          <div class="error-messages" *ngIf="shortDescription.touched">
            <div *ngIf="shortDescription.invalid && shortDescription.dirty">* Short Description needs to be at least 3 characters and 450 characters max </div>
            <div *ngIf="!shortDescription.value">* Short Description can't be blank</div>
          </div>
        </div>

        <div class="form-group">
          <label for="longDescription">Long Description:</label>
          <editor
            id="longDescription"
            tagName="textarea"
            apiKey="no-api-key"
            formControlName="longDescription"
            toolbar="formatselect | bold italic underline | link bullist numlist indent outdent  | forecolor backcolor  "
            [init]="{
          base_url: '/tinymce',
          suffix: '.min', menubar: false,
          plugins: 'link lists formats' ,
          height: '277px'
           }"
            initialValue="Type Here"
            [(ngModel)]="application.longDescription"
          >
          </editor>
          <div class="error-messages" *ngIf="longDescription.touched">
            <div *ngIf="longDescription.invalid && longDescription.dirty">* Long Description needs to be at least 3 characters</div>
            <div *ngIf="!longDescription.value">* Long Description can't be blank</div>
          </div>
        </div>

        <div class="form-group">
          <label for="bannerImage">Image:</label>
          <input
            type="file"
            (change)="onChange($event)"
            id="bannerImage"
            #file name="bannerImage" >
          <div class="error-messages" *ngIf="longDescription.touched">
            <div *ngIf="longDescription.invalid && longDescription.dirty">* File format incorrect, please upload an image</div>
            <div *ngIf="!longDescription.value">* Image is required</div>
          </div>
        </div>

        <hr>
        <div class="form-group-buttons">
          <button type="submit" class="button curved-button" id="submit">Submit</button> &nbsp;&nbsp;
          <button type="button" class="button curved-button" id="cancel" (click)="goToApplicationsList()">Cancel</button>
        </div>
      </form>
    </div>

    <div class="flex-right" *ngIf="application">
      <app-application-card
        [addApplication]="false"
        [application]="application"
        [url]="url"></app-application-card>
    </div>
  </div>
</div>
