<div class="card">
  <div class="card-head">
    <div class="industry">
      Industry:{{getIndustry(this.application.industry)}}
    </div>
    <div class="company-logo">
      <img class="image-logo-size" src="assets/logos/powered-by-hazie-color-transparent.png">
    </div>
  </div>
  <img [src]="url"  class="banner">
  <div class="info">
    <h5 class="card-header">
      {{this.application.title}}
    </h5>
    <div class="location-and-date">
      <div class="card-location"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>{{this.application.location.lineone}}</div>
      <div class="card-date">
        Available: <strong>{{this.application.availableDate| date}}</strong><br>
        Expires: <strong>{{this.application.expiryDate | date}}</strong>
      </div>
    </div>
    <hr>
    <div class="card-content">
      {{this.application.shortDescription}}
    </div>

    <div class="card-buttons-group" *ngIf="!addApplication">
      <button class="button deactive" *ngIf="application.isActive" (click)="deActivate()">Deactivate</button>
      <button class="button active" *ngIf="!application.isActive" (click)="activate()">Activate</button>
      <button class="button delete" (click)="Delete()">Delete</button>
    </div>

  </div>
</div>
