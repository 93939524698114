import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StageModel} from '../../../../Core/Models/Stage.Model';
import {QuestionsAndAnswersService} from '../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {QuestionModel} from '../../../../Core/Models/Question.Model';
import {AnswerModel} from '../../../../Core/Models/Answer.Model';
import {ModalService} from '../../../_modal';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';
import {ToasterNotificationService} from '../../../../Services/ToasterNotificationService/ToasterNotification.Service';
import {OptionTypes} from '../../../../Core/Enums/OptionType.Enum';
import {StagesService} from '../../../../Services/StagesService/Stages/stages.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {StageTypeEnum} from '../../../../Core/Enums/StageType.Enum';

@Component({
  selector: 'app-load-stage-questions',
  templateUrl: './load-stage-questions.component.html',
  styleUrls: ['./load-stage-questions.component.scss']
})
export class LoadStageQuestionsComponent implements OnInit {

  @Input() stageModel: StageModel;
  @Input() stageIndex: number;
  @Input() applicationTitle: number;
  @Output() returnDeleteStageIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() returnEditStageIndex: EventEmitter<number> = new EventEmitter<number>();

  questionModel: QuestionModel;
  questionModelArray: QuestionModel[];
  questionModelArrayCopy: QuestionModel[];
  questionModelArrayTemp: QuestionModel[];
  questionForm: FormGroup;
  commsForm: FormGroup;
  emailsCommsForm: FormGroup;
  odysseyConfigForm: FormGroup;
  addQues: boolean;
  showMessage: boolean;
  questionIndex: number;
  filterCriteria: FilterCriteria;
  interviewForm: FormGroup;
  currentChoice = 'qualified';

  constructor(private questionsAndAnswersService: QuestionsAndAnswersService,
              private formBuilder: FormBuilder,
              public modalService: ModalService,
              private stageService: StagesService,
              private notification: ToasterNotificationService) { }

  ngOnInit(): void {

    this.addQues = true;
    this.showMessage = false;
    this.questionIndex = -1;

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.StageKeys = Array<string>();

    this.questionModel = new QuestionModel();
    this.questionModel.answer = new AnswerModel();
    this.questionModel.dateCreated = new Date();

    this.questionModelArray = Array<QuestionModel>();
    this.questionModelArrayCopy = Array<QuestionModel>();
    this.questionModelArrayTemp = Array<QuestionModel>();

    this.fetchAll(this.stageModel.id);

    this.initiateForm();
  }

  fetchAll(id: string): void {
    this.filterCriteria.StageKeys.push(id);
    this.questionsAndAnswersService.GetByFilterCriteria(this.filterCriteria).subscribe(questions => {
      this.questionModelArrayTemp = questions;

      /* Filter/leave-out Deleted Questions */
      this.questionModelArray = this.questionModelArrayTemp.filter(question => question.isDeleted === false);
      this.questionModelArrayCopy = this.questionModelArrayTemp.filter(question => question.isDeleted === false);

      if (this.questionModelArray.length ===  0) {
        this.showMessage = true;
      } else {
        if (this.stageModel.stageType === StageTypeEnum.Interview || this.stageModel.stageType === StageTypeEnum.Odyssey) {
          this.questionModelArray = [];
        }
        this.showMessage = false;
      }
    });
  }

  initiateForm(): void {
    this.questionForm = this.formBuilder.group({
      stageName: this.stageModel.name,
      questionName: ['', [Validators.required, Validators.minLength(3)]],
      answer: ['', [Validators.required, Validators.minLength(1)]],
      dateCreated: '',
      questionType: ['', [Validators.required]],
      optionsSelect: '',
      optionsMultiSelect: '',
      isActive: ['', [Validators.required]]
    });

    this.interviewForm = this.formBuilder.group({
      starttime: [{value: '', disabled: true}],
      endtime: [{value: '', disabled: true}],
      venue: [{value: '', disabled: true}],
      notes: [{value: '', disabled: true}]
    });

    this.commsForm = this.formBuilder.group({
      qualifiedMessage: [this.stageModel.qualifiedComm, [Validators.required, Validators.minLength(3)]],
      unQualifiedMessage: [this.stageModel.unqualifiedComm, [Validators.required, Validators.minLength(3)]]
    });

    this.emailsCommsForm = this.formBuilder.group({
      emailQualifiedMessage: [this.stageModel.emailQualifiedComm, [Validators.required, Validators.minLength(3)]],
      emailUnQualifiedMessage: [this.stageModel.emailUnqualifiedComm, [Validators.required, Validators.minLength(3)]]
    });

    this.odysseyConfigForm = this.formBuilder.group({
      projectId: [this.stageModel.projectId, [Validators.required, Validators.minLength(4)]]
    });
  }

  addQuestion(stage: StageModel): void {
    this.addQues = true;
    this.initiateForm();
  }

  upsetAddQuestion(question: QuestionModel): void {

    this.markFormFieldsAsTouched();

    if (this.questionForm.valid) {
      this.questionModel = new QuestionModel();
      this.questionModel.answer = new AnswerModel();
      this.questionModel.dateCreated = new Date();
      this.questionModel.question = this.questionForm.controls.questionName.value;
      this.questionModel.answer.answer = this.questionForm.controls.answer.value;
      this.questionModel.answer.questionId = this.questionModel.id;
      this.questionModel.questionType = +this.questionForm.controls.questionType.value;
      this.questionModel.optionType = OptionTypes.Question;
      this.questionModel.optionsSelect = this.questionForm.controls.optionsSelect.value;
      this.questionModel.optionsMultiSelect = this.questionForm.controls.optionsMultiSelect.value;
      this.questionModel.isActive = this.convertStatusBackToIsActive(this.questionForm.controls.isActive.value);
      this.questionModel.stageId = this.stageModel.id;
      this.questionModel.isDeleted = false;
      this.questionModel.applicationId = this.stageModel.applicationId;

      if( this.questionModel.questionType === 2){
        this.questionModel.options = this.questionModel.optionsSelect;
      }
      if (this.questionModel.questionType === 3){
        this.questionModel.options = this.questionModel.optionsMultiSelect;
      }

      this.questionsAndAnswersService.Upsert(this.questionModel).subscribe( response => {
        this.fetchAll(this.stageModel.id); /* Immedietly fetch fresh questions  */
        this.notification.Success('Question Added');
      }, error => {
        console.log(error);
        this.notification.Failure('Question Failed to Save, Please double check the form');
      });

      this.modalService.close('modal-2' + this.stageIndex);
    }
  }

  editQuestion(index: number): void {
    this.addQues = false;
    this.questionIndex = index;

    this.prefillFormForEditing(this.questionModelArray[index]);
  }

  upsetEditQuestion(question: QuestionModel): void {

    this.markFormFieldsAsTouched();

    if (this.questionForm.valid) {
      /* Firstly copy over entire model */
      this.questionModel = this.questionModelArray[this.questionIndex];

      /* Only edit whats necessary */
      this.questionModel.question = this.questionForm.controls.questionName.value;
      this.questionModel.answer.answer = this.questionForm.controls.answer.value;
      this.questionModel.questionType = +this.questionForm.controls.questionType.value;
      this.questionModel.optionsSelect = this.questionForm.controls.optionsSelect.value;
      this.questionModel.optionsMultiSelect = this.questionForm.controls.optionsMultiSelect.value;
      this.questionModel.isActive = this.convertStatusBackToIsActive(this.questionForm.controls.isActive.value);

      if( this.questionModel.questionType === 2){
        this.questionModel.options = this.questionModel.optionsSelect;
      }
      if (this.questionModel.questionType === 3){
        this.questionModel.options = this.questionModel.optionsMultiSelect;
      }

      this.questionsAndAnswersService.Upsert(this.questionModel).subscribe(response => {
        if (response) {

        } else {
          this.notification.Success('Question Updated');
        }
      }, error => {
        console.log(error);
        this.notification.Failure('Question Failed to Update');
      });

      this.modalService.close('modal-2' + this.stageIndex);
    }

  }

  prefillFormForEditing(question: QuestionModel): void {

    if (!question.answer) {
      question.answer = new AnswerModel();
      question.answer.answer = '';
      question.answer.questionId = question.id;
    }

    this.questionForm = this.formBuilder.group({
      questionName: question.question,
      answer: question.answer.answer,
      dateCreated: [{value: question.dateCreated.toLocaleString().slice(0, 10), disabled: true}], // disable the field
      questionType: question.questionType,
      optionsSelect: question.options,
      optionsMultiSelect: question.options,
      isActive: question.isActive
    });
  }

  updateOdysseyProjectConfig(): void {
    this.markOdysseyConfigFormAsTouched();

    if (this.projectId.value.length === 4) {
      console.log(this.projectId.value);
      this.stageModel.projectId = +this.projectId.value;
      this.stageService.Upsert(this.stageModel).subscribe(response => {

        this.notification.Success('Odyssey ProjectID Updated');
      }, error => {
        this.notification.Failure('Odyssey ProjectID Update Unsuccessful, please double check the ID');
      });
    } else if (this.projectId.value.length > 4) {
      this.notification.Alert('Odyssey ProjectID should not exceed 4 digits');
    }
  }

  deleteConfirmation(index: number): void {
    this.questionIndex = index;
  }

  triggerStageDeleteFromParent(): void {
    this.returnDeleteStageIndex.emit(this.stageIndex);
    this.modalService.close('confirmStageDelete' + this.stageIndex); /* stageIndex is for tracking the load-stage-question instance */
  }

  triggerStageEditFromParent(): void {
    this.returnEditStageIndex.emit(this.stageIndex);
  }

  softDeleteQuestion(): boolean {

    if (this.questionModelArray.length === 0) {
      this.modalService.close('confirmation' + this.stageIndex); /* stageIndex is for tracking the load-stage-question instance */
      return false;
    } else {

      this.questionModel = new QuestionModel(); /* to prevent reusing the same GUID over and over */
      this.questionModel.answer = new AnswerModel();
      this.questionModel = this.questionModelArray[this.questionIndex];
      this.questionModel.isDeleted = true; /* Delete */

      this.questionModelArray.splice(this.questionIndex, 1);
      if (this.questionModelArray.length ===  0) {
        this.showMessage = true;
      }
    }

    this.questionsAndAnswersService.Upsert(this.questionModel).subscribe(response => {
      this.notification.Success('Question Deleted');
    });

    this.modalService.close('confirmation' + this.stageIndex); /* stageIndex is for tracking the load-stage-question instance */
    return true;
  }

  saveStageComms(stageIndex: number): void {

    this.markCommsFormFielsAsTouched();

    if (this.commsForm.valid) {

      this.stageModel.qualifiedComm = this.qualifiedMessage.value;
      this.stageModel.unqualifiedComm = this.unQualifiedMessage.value;
      this.stageModel.showComms = true;
      this.stageService.Upsert(this.stageModel).subscribe(response => {
        this.notification.Success('Stage Communication Added Successfully');
      });
      this.modalService.close('stage-comms' + stageIndex);
    }
  }

  saveEmailComms(stageIndex: number): void {

    this.markEmailCommsFormFielsAsTouched();

    if (this.emailsCommsForm.valid) {

      this.stageModel.emailQualifiedComm = this.emailQualifiedMessage.value;
      this.stageModel.emailUnqualifiedComm = this.emailUnQualifiedMessage.value;
      this.stageModel.showComms = true;
      this.stageService.Upsert(this.stageModel).subscribe(response => {
        this.notification.Success('Email Communication Saved Successfully');
      });
      this.modalService.close('email-comms' + stageIndex);
    }
  }

  convertIsActiveToStatus(isActive: boolean): string {
    if (isActive) {
      return 'Active';
    }
    return 'In-Active';
  }

  convertStatusBackToIsActive(status: string): boolean {
    if (status === 'true') {
      return true;
    }
    return false;
  }

  getActive(choice: string): string {
    if (this.currentChoice === choice) {
      return 'qualified';
    } else {
      return 'unqualified';
    }
  }

  setActive(choice: string): void {
    this.currentChoice = choice;
  }

  markFormFieldsAsTouched(): void {
    this.question.markAsTouched();
    this.questionType.markAsTouched();
    this.optionsSelect.markAsTouched();
    this.optionsMultiSelect.markAsTouched();
    this.isActive.markAsTouched();
    this.answer.markAsTouched();
    this.answerFieldSpecialCase();
  }

  markCommsFormFielsAsTouched(): void {
    this.qualifiedMessage.markAsTouched();
    this.unQualifiedMessage.markAsTouched();
  }

  answerFieldSpecialCase(): void {
    if (!this.stageModel.isScoring) {
      this.questionForm.get('answer').setValue('non-scoring');
    }
  }

  markEmailCommsFormFielsAsTouched(): void {
    this.emailQualifiedMessage.markAsTouched();
    this.emailUnQualifiedMessage.markAsTouched();

  }

  markOdysseyConfigFormAsTouched(): void {
    this.projectId.markAsTouched();
  }

  /* -questionForm Getters- */
  get question(): AbstractControl {
    return this.questionForm.get('questionName');
  }

  get questionType(): AbstractControl {
    return this.questionForm.get('questionType');
  }

  get optionsSelect(): AbstractControl {
    return this.questionForm.get('optionsSelect');
  }

  get optionsMultiSelect(): AbstractControl {
    return this.questionForm.get('optionsMultiSelect');
  }

  get isActive(): AbstractControl {
    return this.questionForm.get('isActive');
  }

  get answer(): AbstractControl {
    return this.questionForm.get('answer');
  }

  /* -commsForm Getters- */
  get qualifiedMessage(): AbstractControl {
    return this.commsForm.get('qualifiedMessage');
  }

  get unQualifiedMessage(): AbstractControl {
    return this.commsForm.get('unQualifiedMessage');
  }

  /* -emailCommsForm Getters- */
  get emailQualifiedMessage(): AbstractControl {
    return this.emailsCommsForm.get('emailQualifiedMessage');
  }

  get emailUnQualifiedMessage(): AbstractControl {
    return this.emailsCommsForm.get('emailUnQualifiedMessage');
  }

  /* -Odyssey Config Form Getters- */
  get projectId(): AbstractControl {
    return this.odysseyConfigForm.get('projectId');
  }

  async persistQuestionsOrder(questions: QuestionModel[]): Promise<void> {

    const questionsObs = this.questionsAndAnswersService.UpsertMultiple(questions);
    await questionsObs.toPromise();
  }

  /* This method corrects the Odeyby properties to alight with the changes in the array
  *  This Method makes sure that the oderby updates as the indexes updates
  * */
  async correctOrderByInArray(array: QuestionModel[], fromIndex: number, toIndex: number): Promise<void> {

    const indexes: number[] = [];
    array.forEach(stage => {
      indexes.push(stage.orderby);
    });

    const element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);

    array.forEach((question, index) => {
      question.orderby = indexes[index];
    });

    await this.persistQuestionsOrder(array);
  }

  onDrop($event: CdkDragDrop<QuestionModel[], any>): void {
    moveItemInArray(this.questionModelArray, $event.previousIndex, $event.currentIndex);

    this.correctOrderByInArray(this.questionModelArrayCopy, $event.previousIndex, $event.currentIndex).then(r => console.log(r));
    console.log(this.questionModelArrayCopy);
  }
}
