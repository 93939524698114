import {Component, Input, OnInit} from '@angular/core';
import {ApplicationModel, Industry} from '../../../../Core/Models/Application.Model';
import {ApplicationsService} from '../../../../Services/ApplicationsService/Applications.Service';
import {ToasterNotificationService} from '../../../../Services/ToasterNotificationService/ToasterNotification.Service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {

  @Input() application: ApplicationModel;
  @Input() url;
  @Input() addApplication: boolean;

  toggle: boolean;
  toogleText = '';

  constructor(private applicationsService: ApplicationsService,
              private notification: ToasterNotificationService,
              private router: Router) {}

  ngOnInit(): void {
    if(this.application){
      this.toggle = this.application.isActive;

      if (this.application.isActive === false) {
        this.toogleText = 'Activate';
        this.toggle = false;
      } else {
        this.toogleText = 'Deactivate';
        this.toggle = true;
      }
    }
  }

  Delete(): void {
    this.application.isDeleted = true;
    this.applicationsService.Upsert(this.application).subscribe(res => {
      if (res === null) {
        this.notification.Success('Application Deleted');
        this.goToApplicationsList();
      }
    }, error => {
      this.notification.Failure('Sorry Something went wrong');
    });
  }

  EditApplication(): void {

  }

  deActivate(): void {
    this.application.isActive = false;
    this.applicationsService.Upsert(this.application).subscribe(res => {
      console.log(res);
      if (res === null) {
        this.notification.Success('Application Deactivated');
      }
    }, error => {
        this.notification.Failure('Sorry Something went wrong');
    });
  }

  activate(): void {
    this.application.isActive = true;
    this.applicationsService.Upsert(this.application).subscribe(res => {
      console.log(res);
      if (res === null) {
        this.notification.Success('Application Activated');
      }
    }, error => {
      this.notification.Failure('Sorry Something went wrong');
    });
  }

  getIndustry(industry): string {
    return Industry[industry];
  }

  goToApplicationsList(): void {
    this.router.navigate(['applications']);
  }
}
