import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class RoleService {

    baseUrl = `${environment.identityApiUrl}`;
    roleEndpoints = `${this.baseUrl}/role/`;
    constructor(private https: HttpClient) { }

  AddRole(roleName: string) {
        return this.https.get(`${this.roleEndpoints}AddRole?roleName=${roleName}`);
  }

  AddUserToRole(role: string, email: string) {
        return this.https.get(`${this.roleEndpoints}AddUserToRole?role=${role}&email=${email}` );
    }

  GetUsersOfRole(role: string): Observable<string> {
        return this.https.get<string>(`${this.roleEndpoints}GetUsersOfRole?role=${role}`);
    }

  DelUserFromRole(role: string, email: string): Observable<any> {
        return this.https.delete<any>(`${this.roleEndpoints}DelUserFromRole?role=${role}&email=${email}`);
    }

  GetUserRoles(email: string): Observable<any> {
      return this.https.get<any>(`${this.roleEndpoints}GetUserRoles?email=${email}`);
  }
}
