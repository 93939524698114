import {BaseModel} from './Base.Model';
import {LocationCompanyModel} from './LocationCompany.Model';

export class CompanyModel extends BaseModel {
  isActive: boolean;
  name: string;
  contactDetails: string;
  address: LocationCompanyModel;
  primaryColor: string;
  secondaryColor: string;
  logo: string;

}
