import {Guid} from 'guid-typescript';
import {BaseModel} from './Base.Model';

export class LocationCompanyModel extends BaseModel{

  companyId: string;
  lineone: string;
  linetwo: string;
  linethree: string;
  linefour: string;
}
