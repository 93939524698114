import { Injectable } from '@angular/core';
import {DocumentUploadModel} from '../../Core/Models/DocumentUpload.Model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {environment} from '../../../environments/environment';
import {BannerImageModel} from '../../Core/Models/BannerImage.Model';
import {DocumentModel} from '../../Core/Models/Document.Model';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  baseUrl = environment.baseUrl;
  uploadEndpoints = `${this.baseUrl}/documents/DocumentUploads/`;

  constructor(private https: HttpClient) {
  }

  GetById(id: Guid): Observable<DocumentUploadModel> {
    return this.https.get<DocumentUploadModel>(`${this.uploadEndpoints}GetById?id=${id}`);
  }
  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<DocumentUploadModel[]> {
    return this.https.post<DocumentUploadModel[]>(`${this.uploadEndpoints}all`, filterCriteria);
  }

  uploadCandidate(formData, company: string, application: string, candidate: Guid): Observable<DocumentUploadModel> {
    return this.https.post<any>(`${this.uploadEndpoints}company=${company}&application=${application}&candidate=${candidate}`, formData);
  }

  uploadApplication(formData, company: string, application: string): Observable<DocumentUploadModel> {
    return this.https.post<any>(`${this.uploadEndpoints}UploadApplication?company=${company}&application=${application}`, formData);
  }

  uploadCompany(formData, company: string): Observable<DocumentUploadModel> {
    return this.https.post<any>(`${this.uploadEndpoints}UploadCompany?company=${company}`, formData);
  }

  // Upsert( model: DocumentUploadModel): Observable<any> {
  //   // const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  //   return this.https.post<DocumentUploadModel>(`${this.uploadEndpoints}Upsert`, model);
  // }

  isActive(id: Guid): Observable<string> {
    return this.https.get<string>(`${this.uploadEndpoints}/isActive?id=${id}`);
  }

  GetFileURL(filename: string): Observable<string> {
    return this.https.get<string>(`${this.uploadEndpoints}GetFileURL?filename=${filename}`);
  }

  /* ------------------AmazonS3 Service Calls------------------ */

  uploadFile(bucketName: string, formData): Observable<string> {
    return this.https.post<string>(`${this.uploadEndpoints}AddFile/${bucketName}`, formData);
  }

  UpsertBanner(bannerImageModel: BannerImageModel): Observable<any> {
    return this.https.post<any>(`${this.uploadEndpoints}UpsertBanner`, bannerImageModel);
  }

  GetApplicationBannerInfo(applicationId: string): Observable<BannerImageModel> {
    return this.https.get<BannerImageModel>(`${this.uploadEndpoints}GetApplicationBannerInfo?applicationId=${applicationId}`);
  }

  DeleteFile(bannerImageModel: BannerImageModel, bucketName: string): Observable<any> {
    return this.https.delete<any>(`${this.uploadEndpoints}DeleteFile/${bucketName}/${bannerImageModel.fileName}`);
  }

  UpsertDocument(document: DocumentModel): Observable<any> {
    return this.https.post<any>(`${this.uploadEndpoints}UpsertDocument`, document);
  }

  FetchBannerUrl(applicationId: string): Observable<string> {
    return this.https.get<string>(`${this.uploadEndpoints}GetBannerUrl?applicationId=${applicationId}`);
  }

  GetDocumentsInfoList(candidateId: string): Observable<DocumentModel[]> {
    return this.https.get<DocumentModel[]>(`${this.uploadEndpoints}GetDocumentsInfoList?candidateId=${candidateId}`);
  }

  GetDocumentInfo(candidateId: string, purpose: string): Observable<DocumentModel> {
    return this.https.get<DocumentModel>(`${this.uploadEndpoints}GetDocumentInfo?candidateId=${candidateId}&purpose=${purpose}`);
  }

  GetAllDocuments(): Observable<DocumentModel[]> {
    return this.https.get<DocumentModel[]>(`${this.uploadEndpoints}GetAllDocuments`);
  }
}
