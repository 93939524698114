import { Component, OnInit } from '@angular/core';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {ActivatedRoute, Router} from '@angular/router';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {StagesService} from '../../../Services/StagesService/Stages/stages.service';
import {StagescandidatejobService} from '../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {StageWithCountModel} from '../../../Core/Models/StageWithCount.Model';

@Component({
  selector: 'app-view-application',
  templateUrl: './view-application.component.html',
  styleUrls: ['./view-application.component.scss']
})
export class ViewApplicationComponent implements OnInit {

  applicationId: string;
  applicationTitle: string;
  stageCount: number;
  candidateCount: number;
  candidatesSet: Set<string>;
  stageObjects: StageModel[];
  filterCriteria: FilterCriteria;
  applicationsCount: number;
  stagesWithCount: StageWithCountModel[];
  barGraphColors: string[];

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private stageService: StagesService,
              private stageCandidateApplication: StagescandidatejobService,
              private applicationsService: ApplicationsService) { }

  ngOnInit(): void {
    this.applicationId  = this.activatedRoute.snapshot.paramMap.get('id');
    this.applicationTitle = this.activatedRoute.snapshot.queryParamMap.get('title');

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.candidatesSet = new Set<string>();
    this.stagesWithCount = Array<StageWithCountModel>();
    this.barGraphColors = Array<string>();

    this.getApplicationStages(this.applicationId);
    this.fetchCandidatesGuids();
  }

  goToApplicationStages(): void {
    this.router.navigate(['application-stages', this.applicationId],
      {
        queryParams: {title: this.applicationTitle}
      });
  }

  goToCandidatesList(): void {
    this.router.navigate(['candidates-list', this.applicationId],
      {
        queryParams: {title: this.applicationTitle}
      });
  }

  getApplicationStages(id: string): void {
    this.filterCriteria.ApplicationsKeys.push(id);
    this.stageService.GetByFilterCriteria(this.filterCriteria).subscribe(stages => {
      this.stageObjects = stages;
      this.stageCount = this.stageObjects.length;

      this.stageObjects.forEach(stage => {

        const stageWithCount = new StageWithCountModel();
        stageWithCount.stageId = stage.id;
        stageWithCount.name = stage.name;
        stageWithCount.count = 0;

        this.stagesWithCount.push(stageWithCount);
        this.barGraphColors.push();
      });
      console.log(this.stageCount);
    });
  }

  fetchCandidatesGuids(): void {

    this.stageCandidateApplication.GetApplicationCandidates(this.applicationId).subscribe(guids => {
      /* These shenanigans are for avoiding duplicates */
      guids.forEach(guid => {
        this.candidatesSet.add(guid);
      });

      this.candidateCount = this.candidatesSet.size;
      console.log(this.candidatesSet);
    });
  }



}
