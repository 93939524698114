import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {UserModel} from '../../Core/Models/UserModel';

@Injectable()
export class UserService {

  baseUrl = `${environment.identityApiUrl}`;
  userEndpoints = `${this.baseUrl}/user/`;

  constructor(private https: HttpClient) {
  }

  GetUser(): Observable<UserModel[]> {
   return this.https.get<UserModel[]>(`${this.userEndpoints}GetUsers`);
  }

}
