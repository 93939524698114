import {BaseModel} from './Base.Model';
import {AnswerModel} from './Answer.Model';
import {QuestionTypes} from '../Enums/QuestionType.Enum';
import {OptionTypes} from '../Enums/OptionType.Enum';

export class QuestionModel extends BaseModel {
  question: string;
  questionType: QuestionTypes;
  optionType: OptionTypes;
  optionsSelect: string;
  optionsMultiSelect: string;
  options: string;
  isActive: boolean;
  answer: AnswerModel;
  stageId: string;
  dateCreated: Date;
  orderby: number;
  isDeleted: boolean;
  applicationId: string;
  candidateAnswer: string; /* This is temporary */
}
