import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './Components/accounts/login/login.component';
import {RegisterComponent} from './Components/accounts/register/register.component';
import {ForgetPasswordComponent} from './Components/accounts/forget-password/forget-password.component';
import {AddApplicationComponent} from './Components/applications/add-application/add-application.component';
import {ApplicationsListComponent} from './Components/applications/applications-list/applications-list.component';
import {ApplicationsStagesComponent} from './Components/applications/applications-stages/applications-stages.component';
import {LandingPageComponent} from './Components/landing-page/landing-page.component';
import {AdminComponent} from './Components/admin/admin.component';
import {CandidatesStagesComponent} from './Components/candidates/candidates-stages/candidates-stages.component';
import {CandidatesProfileComponent} from './Components/candidates/candidates-profile/candidates-profile.component';
import {CandidatesListComponent} from './Components/candidates/candidates-list/candidates-list.component';
import {ViewApplicationComponent} from './Components/applications/view-application/view-application.component';
import {UserEditComponent} from './Components/config/users/user-edit/user-edit.component';
import {CompanyComponent} from './Components/companies/company/company.component';
import {AuthGuard} from './guards/auth.guard';
import {RegisterSuccessComponent} from './Components/accounts/register-success/register-success.component';
import {EditApplicationComponent} from './Components/applications/edit-application/edit-application.component';
import {UserGridComponent} from './Components/config/users/user-grid/user-grid.component';
import {ErrorComponent} from './Components/accounts/error/error.component';
import {VerifyUserComponent} from './Components/accounts/verify-user/verify-user.component';
import {ChangePasswordComponent} from './Components/accounts/change-password/change-password.component';

const routes: Routes = [

  {path: 'view-application/:id', component: ViewApplicationComponent, canActivate: [AuthGuard]},
  {path: 'company', component: CompanyComponent, canActivate: [AuthGuard]},
  {path: 'add-application', component: AddApplicationComponent, canActivate: [AuthGuard]},
  {path: 'edit-application/:id', component: EditApplicationComponent},
  {path: 'error', component: ErrorComponent},
  {path: 'verifyUser', component: VerifyUserComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'registerSuccess', component: RegisterSuccessComponent},
  {path: 'login', component: LoginComponent},
  {path: 'forgotPassword', component: ForgetPasswordComponent},
  {path: 'changePassword/:email/:code', component: ChangePasswordComponent},
  {path: 'applications', component: ApplicationsListComponent, canActivate: [AuthGuard]},
  {path: 'application-stages/:id', component: ApplicationsStagesComponent, canActivate: [AuthGuard]},
  {path: 'candidate-stages/:id', component: CandidatesStagesComponent, canActivate: [AuthGuard]},
  {path: 'candidate-profile/:id', component: CandidatesProfileComponent, canActivate: [AuthGuard]},
  {path: 'candidates-list/:id', component: CandidatesListComponent , canActivate: [AuthGuard]},
  {path: 'landing-page', component: LandingPageComponent,  canActivate: [AuthGuard]},
  {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
  {path: 'users-list', component: UserGridComponent,  canActivate: [AuthGuard]},
  {path: 'users-edit/:email', component: UserEditComponent,  canActivate: [AuthGuard]},
  {path: '', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

