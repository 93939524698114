<app-navbar></app-navbar>

<div class="main-container">
  <div class="top-cards">
    <div class="card">
      <img src="../../../assets/icons/applications.svg" alt="">
      <div class="info">
        <div class="text">Applications</div>
        <div class="number">{{applicationsCount}}</div>
      </div>
    </div>

    <div class="card">
      <img src="../../../assets/icons/user.svg" alt="">
      <div class="info">
        <div class="text">Candidates</div>
        <div class="number">{{candidatesCount}}</div>
      </div>
    </div>

    <div class="card">
      <img src="../../../assets/icons/mail.svg" alt="">
      <div class="info">
        <div class="text">Emails Delivered</div>
        <div class="number">{{emailsCount}}</div>
      </div>
    </div>
  </div>

  <div class="graph1-canvas">
    <div class="graph1-card">
      <h2>Top Applications</h2>
      <canvas baseChart
              [options]="pieChartOptions"
              [colors]="pieChartDataChartColors"
              [data]="pieChartData"
              [labels]="pieChartLabels"
              [chartType]="pieChartType"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)">

      </canvas>
    </div>
  </div>

<!--  <div class="graph2-canvas">-->
<!--    <div class="graph2-card">-->
<!--      <h2>Overal Referrals</h2>-->
<!--      <canvas baseChart-->
<!--              [options]="pieChartOptions"-->
<!--              [colors]="pieChartDataChartColors"-->
<!--              [data]="pieChartData"-->
<!--              [labels]="pieChartLabels"-->
<!--              [chartType]="pieChartType"-->
<!--              (chartHover)="chartHovered($event)"-->
<!--              (chartClick)="chartClicked($event)"></canvas>-->
<!--    </div>-->
<!--  </div>-->
</div>
