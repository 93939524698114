<app-navbar></app-navbar>
<div class="main-container">
  <div class="card">
    <div class="headers">
      Manage users <br>
      <h2>Users</h2>
    </div>

    <div class="table">
      <div class="table-head">
        <div class="table-cell">Email</div>
        <div class="table-cell">Roles</div>
        <div class="table-cell">Actions</div>
      </div>

      <div class="table-body">
        <div class="table-row" *ngFor="let item of items">
          <div class="table-cell">{{item.email}}</div>
          <div class="table-cell">
            <span *ngFor="let roleName of item.roleNames">
                   {{ roleName }}
            </span></div>
          <div class="table-cell button-row">
            <button class="button" type="submit" (click)="delete(item)" ><i class="fa fa-trash fa-2x" aria-hidden="true"></i></button>
            <button class="button" type="submit" routerLink="/users-edit/{{item.email}}"><i class="fa fa-pencil fa-2x" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--<div class="main-container">-->
<!--  <div class="card">-->
<!--    <div class="header">-->
<!--      Manage Users-->
<!--    </div>-->

<!--    <div *ngIf="isloaded" class="card-body card-body-full-screen">-->
<!--      <fieldset>-->
<!--        <legend>Users</legend>-->
<!--        <table class="table table-sm table-striped table-no-border">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>Email</th>-->
<!--            <th>Roles</th>-->
<!--            <th>Actions</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->

<!--          <tr *ngFor="let item of items">-->
<!--            <td>{{item.email}}</td>-->
<!--            <td> <span *ngFor="let roleName of item.roleNames">-->
<!--                   {{ roleName }}-->
<!--            </span></td>-->
<!--            <td>-->
<!--              <button (click)="delete(item)" class="btn btn-outline-secondary btn-sm fa fa-trash-o"></button>-->
<!--              <button routerLink="/users-edit/{{item.email}}"-->
<!--                      class="btn btn-outline-secondary btn-sm fa fa-pencil"></button>-->
<!--            </td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->

<!--      </fieldset>-->

<!--    </div>-->

<!--  </div>-->
<!--</div>-->



