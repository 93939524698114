import { Component, OnInit } from '@angular/core';
import {ChartOptions} from 'chart.js';
import {ApplicationsService} from '../../Services/ApplicationsService/Applications.Service';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {CandidatesService} from '../../Services/CandidatesService/candidates.service';
import {ApplicationModel} from '../../Core/Models/Application.Model';
import {StagescandidatejobService} from '../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {NotificationsService} from '../../Services/NotificationsService/Notifications.Service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  filterCriteria: FilterCriteria;
  applicationsCount: number;
  candidatesCount: number;
  emailsCount: number;
  applications: ApplicationModel[];
  applicationsWithStats: Map<string, number>;
  applicationsWithCount: Array<{name: string, count: number}>;
  candidatesSet: Set<string>;

  /* Pie Chart */
  pieChartLabels: string[];
  pieChartData: number[];
  pieChartType: string = 'pie';
  pieChartDataChartColors: any[] = [];

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {position: 'right',
      align: 'center',
      labels: {
        boxWidth : 10
      }},
  };

  constructor(private applicationsService: ApplicationsService,
              private candidatesService: CandidatesService,
              private stageCandidateApplication: StagescandidatejobService,
              private notificationsService: NotificationsService) { }

  ngOnInit(): void {

    this.filterCriteria = new FilterCriteria();

    this.pieChartDataChartColors = [
      {
        backgroundColor: ['#FA67C3', '#D16A92', '#8B40A5', '#A0478C',
          '#A0478C', '#01216F', '#6BDC84', '#2EC69D', '#27AEA9', '#2993CD', '#E90C82',
        ]
      }];


    this.applicationsWithStats = new Map<string, number>();
    this.applicationsWithCount = Array<{name: string, count: number}>();

    this.getApplicationsCount();
    this.getCandidatesCount();
    this.getApplicationsList();
    this.getEmailsCount();

  }

  getApplicationsCount(): void {
    this.applicationsService.GetAllCount(this.filterCriteria).subscribe(res => {
      console.log(res);
      this.applicationsCount = res;
    }, error => {
      console.log(error);
      this.applicationsCount = 0;
    });
  }

  getApplicationsList(): void {
    this.applicationsService.GetByFilterCriteria(this.filterCriteria).subscribe( res => {
      this.applications = res.filter(application => application.isDeleted === false);
      for (let i = 0; i < this.applications.length; i++) {
        this.getCandidatesPerApplication(this.applications[i]);
      }

    });

    this.compileStatsForPieChart(); /* Call again to avoid graph errors */
  }

  compileStatsForPieChart(): void {
    this.pieChartLabels = [];
    this.pieChartData = [];

    if (this.applicationsWithCount) {
      for (let i = 0; i < this.applicationsWithCount.length; i++) {
        this.pieChartLabels.push(this.applicationsWithCount[i].name );
        this.pieChartData.push(this.applicationsWithCount[i].count);
      }
    }

  }

  getCandidatesCount(): void {
    this.candidatesService.GetAllCount(this.filterCriteria).subscribe(res => {
      this.candidatesCount = res;
    }, error => {
      this.candidatesCount = 0;
    });
  }

  getEmailsCount(): void {
    this.notificationsService.GetAllCount().subscribe( res => {
      this.emailsCount = res;
    }, error => {
      this.emailsCount = 0;
    });
  }

  getCandidatesPerApplication(application: ApplicationModel): number {
    this.candidatesSet = new Set<string>();
    this.stageCandidateApplication.GetApplicationCandidates(application.id).subscribe( guids => {
      /* These Set shenanigans are for avoiding duplicates */
      this.candidatesSet.clear();
      guids.forEach(guid => {
        this.candidatesSet.add(guid);
      });

      const appli = {
        name: application.title,
        count: this.candidatesSet.size
      };
      this.applicationsWithStats.set(application.title, this.candidatesSet.size);
      this.applicationsWithCount.push(appli);

      if (this.applicationsWithCount.length === this.applications.length) {
        this.compileStatsForPieChart(); /* This call makes sure the graphs have data */
      }
    });

    return this.candidatesSet.size;
  }

  getEmailsDeliveredCount(): void {

  }

  public chartClicked(e:any):void {

  }

  public chartHovered(e:any):void {

  }


}
