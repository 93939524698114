<app-navbar></app-navbar>
<div class="main-container">
  <div class="card">
    <p>Assign User to Roles</p>
    <hr>
    <button type="button" class="button" (click)="save()">Save</button>
    <h2>User Details</h2>

    <div class="username-row">
      <div class="cell">Username</div>
      <div class="cell">{{this.model.username}}</div>
    </div>

    <h2>Roles</h2>
    <div class="table">
      <div class="table-head">
        <div class="table-cell">Role</div>
        <div class="table-cell">Is Authorised</div>
      </div>
      <div class="table-body">
        <form *ngIf="model" [formGroup]="editForm" novalidate>

        </form>

        <div class="table-row"
             *ngFor="let item of this.items | filter:term | paginate: config;" >
          <div class="table-cell">
            {{item.name}}
          </div>
          <div class="table-cell">
            <input type="checkbox" name="roleIsAuthorized" [checked]="item.isLoaded" (click)="setIsRoleAuthorised(item.id)">
          </div>
        </div>

        <div class="pagination">
          <div class="TM.Pagination" *ngIf="items.length > 0  && removePagination === false">
            <pagination-controls style="text-align: center" (pageChange)="pageChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

