import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';

@Injectable()
export class LoaderSpinnerService {
  constructor(private spinnerService: NgxSpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Load Spinner

    return next
      .handle(request).pipe(tap(event => {
        this.spinnerService.show();
      }), finalize(() => {
        this.spinnerService.hide();
      }));

  }
}
