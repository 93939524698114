<app-navbar></app-navbar>

<div class="main-container">
  <div class="activity-indicators-top">
    <div class="active">Active</div>
    <div class="in-active">InActive</div>
  </div>

  <div class="table">
    <div class="table-head" *ngIf="!showMessage">
      <div class="table-cell">Title</div>
      <div class="table-cell">Short Description</div>
      <div class="table-cell">Created</div>
      <div class="table-cell">Action</div>
    </div>

    <div class="table-body">
      <div class="message" *ngIf="showMessage">
        No Applications Found.
      </div>
      <div *ngIf="!showMessage">
        <div class="table-row activity-indicators-row"
             [class.active]="application.isActive === true"
             [class.in-active]="application.isActive === false"
             *ngFor="let application of filterdApps | filter:term | paginate: config;">
          <div class="table-cell">{{application.title}}</div>
          <div class="table-cell">{{application.shortDescription | slice:0:170}}...</div>
          <div class="table-cell">{{application.dateCreated | date}} <br> {{application.dateCreated | date: 'h:mm a'}}</div>
          <div class="table-cell">
            <div class="dropdown">
              <button type="button" class="button">Manage &nbsp;<i class="fa fa-caret-down" aria-hidden="true"></i></button>
              <div class="dropdown-content">
                <a (click)="goToApplicationDetails(application)">View Application</a>
                <a (click)="goToApplicationStages(application)">Application Stages</a>
                <a (click)="goToEditApplication(application)">Edit Application</a>
                <a (click)="goToCandidatesList(application)">View Candidates</a>
              </div>
            </div>

          </div>
        </div>
        <div class="pagination">
          <div class="TM.Pagination" *ngIf="applications.length > 0  && removePagination === false">
            <pagination-controls style="text-align: center" (pageChange)="pageChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

