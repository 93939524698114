<app-navbar></app-navbar>
<div class="main-container">
  <div class="top-container">
    <div class="application-name">{{applicationTitle}} <br><br> Stages</div>
    <div class="candidate-name">Candidate: {{candidateName}} {{candidateSurname}}</div>
  </div>

  <div class="tabs">
    <div class="tab" *ngFor="let stage of stageObjects; let i = index">
      <input type="checkbox" id="chck{{i}}">
      <label class="tab-label" for="chck{{i}}">{{i + 1}}. {{stage.name}}</label>
      <div class="tab-content" id="stage{{i}}">
        <app-load-candidate-answers
          [candidateName]="candidateName"
          [candidateSurname]="candidateSurname"
          [stageModel]="stage"
          [candidateId]="candidateId"
          [applicationId]="applicationId"
          [applicationTitle]="applicationTitle"
          [candidateDocuments]="candidateDocuments"
          [stageIndex]="i"
        ></app-load-candidate-answers>
      </div>
    </div>
  </div>
</div>

