import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';

import {UserItemModel} from './user-item.model';


@Injectable()
export class UserDataService {
  private serviceIdentityUrl = environment.identityApiUrl + '/UserList';
  private apiUrl = environment.apiUrl + '/user';

  constructor(private http: HttpClient) {
  }

  getUsers(): Observable<UserItemModel[]> {
    return this.http.get<UserItemModel[]>(this.apiUrl + '/GetAllUsers');
  }

  getEntity(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/GetUserById?id=${id}`);
  }

  updateIsVerified(id, isVerified): Observable<any> {
    return this.http.get<any>(`${this.serviceIdentityUrl}?id=${id}&isVerified=${isVerified}`);
  }

  updateIsAccountClosed(id, isAccountClosed): Observable<any> {
    return this.http.get<any>(`${this.serviceIdentityUrl}?id=${id}&isAccountClosed=${isAccountClosed}`);
  }

  updateUser(user: UserItemModel) {
    return this.http.put<any>(`${this.apiUrl}/UpdateUser?id=${user.id}`, user);
  }

  deleteUserById(user) {
    return this.http.delete(`${this.apiUrl}/Delete?id=${user.id}`);
  }
}
