import {BaseModel} from './Base.Model';
import {Gender} from './Gender.Model';

class LocationModel {
}

export class CandidateModel extends BaseModel{
  email: string;
  surname: string;
  name: string;
  gender: Gender;
  identityNumber: string;
  passportNumber: number;
  countryOfBirth: string;
  isActive: boolean;
  location: LocationModel;
  applieddate: Date;
}
