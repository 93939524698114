import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

import {Guid} from 'guid-typescript';
import { Observable } from 'rxjs/internal/Observable';
import {CandidateModel} from '../../Core/Models/CandidateModelModell.Model';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  baseUrl = environment.baseUrl;
  candidateEndpoints = `${this.baseUrl}/Candidates/Candidates/`;
  constructor(private https: HttpClient) { }

  GetById(id: string): Observable<CandidateModel> {
    return this.https.get<CandidateModel>(`${this.candidateEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<CandidateModel[]> {
    return this.https.post<CandidateModel[]>(`${this.candidateEndpoints}all`, filterCriteria);
  }

  GetAllCount(filterCriteria: FilterCriteria): Observable<number> {
    return this.https.post<number>(`${this.candidateEndpoints}allcount`, filterCriteria);
  }

  Upsert(model: CandidateModel): Observable<string> {
    return this.https.post<string>(`${this.candidateEndpoints}Upsert`, model);
  }
  isActive(id: Guid): Observable<string> {
    return this.https.get<string>(`${this.candidateEndpoints}isActive?id=${id}`);
  }


}
