import {StageProgress} from '../Enums/StageProgress.Model';

export class CandidateStageProgressModel {
  candidateId: string;
  stageId: string;
  applicationId: string;
  name: string;
  stageName: string;
  orderby: number;
  appliedDate: Date;
  progress: StageProgress;
  isPromoted: boolean;
}

