export enum EmailTypeEnum {
  PhaseOne,
  Unsuccessful,
  TTSAssessment,
  RegretEmailTTS,
  ScheduleInterview,
  ScheduleCandidateInterview,
  TTSAssesmentUpdate,
  CustomEmail,
  UploadDocument
}
