<div class="bar-graph-wrapper" *ngIf="barChartLabels.length > 0">
  <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [chartType]="barChartType"
          [colors]="barChartColors">
  </canvas>
</div>

<div class="bar-graph-no-stages" *ngIf="stagesWithCount.length === 0">
  {{applicationTitle}} has no stages
</div>

<div class="stages" *ngIf="stagesWithCount.length">
  <div class="card" *ngFor="let stage of stagesWithCount">
    <app-pie-chart [stage]="stage"></app-pie-chart>
  </div>
</div>

